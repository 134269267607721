import React, { Fragment } from "react";
import { url, grandrounds } from "../settings";

// Components
import Spinner from "../Spinner";
import TabNavigator from "../Components/Navigation/TabNavigator";
import Header from "../Components/Navigation/Header";
import CaseContainer from "../CaseCard/CaseContainer";
import CollectionThumbnail from "./CollectionThumbnail";

// Styles
import styled from "styled-components";
import { fontFamily, fontType, grayscale } from "../utilities";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const CASESET = gql`
  query CASESET($id: Int!) {
    collection: casesets(id: $id) {
      id
      setTitle
      setDescription
      caseSet {
        id
        hashid
        caseCategory
        chiefComplaint
        clinicalScenario
        caseQuestion
        explanation
        caseTags
        casePhoto
        casePhotoThumb
        isPremium
        isDraft
        attempts
        correct
        incorrect
        layout
        caseType
        totalRating
        ratingAvg
        stemSet {
          id
          stem
        }
        questionSet {
          id
          question
        }
      }
    }
  }
`;

export default function CollectionDetail(props) {
  let collectionIdentity;
  if (props.match.params.collectionId == 'grandrounds') {
    collectionIdentity = grandrounds;
  } else {
    collectionIdentity = props.match.params.collectionId;
  }

  return (
    <Query
      query={CASESET}
      variables={{
        id: collectionIdentity
      }}
    >
      {({ loading, data }) => {
        if (loading) return <Spinner />;
        const collection = data.collection[0];
        const cases = data.collection[0].caseSet;
        return (
          <Fragment>
            <Header history={props.history} />
            <CollectionContainer>
              <CollectionInfo>
                <CollectionThumbnail collectionId={collection.id} />
                <p className="collection-label">Collection</p>
                <h1>{collection.setTitle}</h1>
                <p className="description">{cases.length} Cases</p>
                <p className="description">{collection.setDescription}</p>
              </CollectionInfo>
              <CasesContainer>
                <CaseContainer
                  cases={cases}
                  mobile={"list"}
                  mobileLayout={"list"}
                  desktop={"standard"}
                  desktopLayout={"grid"}
                  history={props.history}
                />
                <CaseContainer
                  cases={cases}
                  mobile={"list"}
                  mobileLayout={"list"}
                  history={props.history}
                />
              </CasesContainer>
            </CollectionContainer>
            <TabNavigator />
          </Fragment>
        );
      }}
    </Query>
  );
}
const CollectionContainer = styled.div`
  max-width: 1300px;
  margin: auto;
  margin-top: 75px;
  margin-bottom: 75px;
  display: grid;
  grid-template-columns: 300px auto;
  grid-column-gap: 35px;
  @media (max-width: 1300px) {
    grid-column-gap: 20px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 200px auto;
  }
  @media (max-width: 625px) {
    grid-template-columns: 100%;
    grid-row-gap: 20px;
  }
  @media (max-width: 425px) {
    margin-top: 60px;
  }
`;
const CollectionInfo = styled.div`
  h1 {
    margin-top: 0;
    font-family: ${fontFamily.sansSerif};
    ${fontType.pageTitle}
  }
  .collection-label {
    margin-bottom: 0;
    font-family: ${fontFamily.sansSerif};
    color: ${grayscale.lightDark};
    ${fontType.small};
  }
  .description {
    font-family: ${fontFamily.sansSerif};
    ${fontType.small}
  }
  @media (max-width: 1300px) {
    margin-left: 15px;
  }
  @media (max-width: 625px) {
    margin: 0 15px;
  }
  @media (max-width: 625px) {
    margin: 0 10px;
    h1 {
      margin: 0;
    }
    .description {
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
`;
const CasesContainer = styled.div``;
