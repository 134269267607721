import { css } from "styled-components";

export const fontFamily = {
  sansSerif: "futura-pt, sans-serif",
  serif: "adobe-text-pro, serif"
};

export const fontType = {
  pageTitle: css`
    font-size: 28px;
    @media (max-width: 768px) {
      font-size: 22px;
    }
    @media (max-width: 425px) {
      font-size: 18px;
    }
  `,
  small: css`
    font-size: 14px;
    font-weight: 300;
    @media (max-width: 425px) {
      font-size: 12px;
    }
  `,
};
