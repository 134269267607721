import React from "react";
import PropTypes from "prop-types";

// Styles
import styled from "styled-components";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const CASESET = gql`
  query CASESET($id: Int!) {
    collection: casesets(id: $id) {
      id
      setPhoto
      setTitle
    }
  }
`;

export default function CollectionThumbnail(props) {
  return (
    <Query
      query={CASESET}
      variables={{
        id: props.collectionId
      }}
    >
      {({ loading, data }) => {
        if (loading) return null;
        const collection = data.collection[0];

        if (collection.setPhoto) {
          return <Thumbnail src={collection.setPhoto} />;
        } else {
          return null;
        }
      }}
    </Query>
  );
}

CollectionThumbnail.propTypes = {
  collectionId: PropTypes.string
};

const Thumbnail = styled.img`
  object-fit: cover;
  height: 250px;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    height: 150px;
  }
  @media (max-width: 625px) {
    height: 225px;
    margin-bottom: 0;
  }
`;
