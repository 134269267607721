import React, { Fragment, Component, useState } from "react";
import { url } from "../settings";

// Elements
import { CaseBlock, StyledLabel } from "../elements";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/pro-solid-svg-icons";

// Styles
import styled from "styled-components";

// Apollo, GraphQL
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";

const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      id
      username
    }
  }
`;

const HANDLE_RATING = gql`
  mutation HANDLE_RATING($userId: Int!, $caseId: Int!, $rating: Int!) {
    handleCaseRating(userId: $userId, caseId: $caseId, rating: $rating) {
      case {
        id
        ratingAvg
        totalRating
      }
    }
  }
`;

export default function CaseRating(props) {
  const [rated, setRated] = useState(false);
  const [selectedRating, setSelectedRating] = useState();
  const [updatedRating, setUpdatedRating] = useState(0);
  const [updatedRatingTotal, setUpdatedRatingTotal] = useState(0);

  const { ratingAvg, totalRating } = props.caseObject;

  function handleStarClick(rating) {
    const calcNewRating = (ratingAvg + rating) / (totalRating + 1);
    setRated(true);
    setSelectedRating(rating);
  }

  function totalCaseRating() {
    if (props.totalRating) {
      return props.totalRating + 1;
    } else {
      return 1;
    }
  }

  function renderCaseStars(newRating) {
    if (newRating < 0.5) {
      return (
        <Fragment>
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
        </Fragment>
      );
    } else if (newRating >= 0.5 && newRating < 1) {
      return (
        <Fragment>
          <StyledStarHalf icon={faStarHalf} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
        </Fragment>
      );
    } else if (newRating >= 1 && newRating < 1.5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
        </Fragment>
      );
    } else if (newRating >= 1.5 && newRating < 2) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarHalf icon={faStarHalf} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
        </Fragment>
      );
    } else if (newRating >= 2 && newRating < 2.5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
        </Fragment>
      );
    } else if (newRating >= 2.5 && newRating < 3) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarHalf icon={faStarHalf} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
        </Fragment>
      );
    } else if (newRating >= 3 && newRating < 3.5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
        </Fragment>
      );
    } else if (newRating >= 3.5 && newRating < 4) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarHalf icon={faStarHalf} size="lg" />
          <StyledStar icon={faStar} size="lg" />
        </Fragment>
      );
    } else if (newRating >= 4 && newRating < 4.5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStar icon={faStar} size="lg" />
        </Fragment>
      );
    } else if (newRating >= 4.5 && newRating < 5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarHalf icon={faStarHalf} size="lg" />
        </Fragment>
      );
    } else if ((newRating = 5)) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
          <StyledStarFull icon={faStar} size="lg" />
        </Fragment>
      );
    }
  }
  const caseId = props.caseObject.id;
  return (
    <RatingBlock answered={props.answered}>
      {!rated && (
        <Query query={CURRENT_USER}>
          {({ loading, data }) => {
            if (loading) return <Loading>Loading...</Loading>;
            const { me } = data;
            const userId = me.id;
            return (
              <Mutation
                mutation={HANDLE_RATING}
                onCompleted={data => {
                  const updatedCase = data.handleCaseRating.case;
                  setUpdatedRating(updatedCase.ratingAvg);
                  setUpdatedRatingTotal(updatedCase.totalRating);
                }}
              >
                {handleCaseRating => (
                  <StarContainer>
                    <StyledLabel>How would you rate this case?</StyledLabel>
                    <StyledStarClick
                      icon={faStar}
                      size="2x"
                      onClick={() => {
                        handleStarClick(1);
                        handleCaseRating({
                          variables: { userId, caseId, rating: 1 }
                        });
                      }}
                    />
                    <StyledStarClick
                      icon={faStar}
                      size="2x"
                      onClick={() => {
                        handleStarClick(2);
                        handleCaseRating({
                          variables: { userId, caseId, rating: 2 }
                        });
                      }}
                    />
                    <StyledStarClick
                      icon={faStar}
                      size="2x"
                      onClick={() => {
                        handleStarClick(3);
                        handleCaseRating({
                          variables: { userId, caseId, rating: 3 }
                        });
                      }}
                    />
                    <StyledStarClick
                      icon={faStar}
                      size="2x"
                      onClick={() => {
                        handleStarClick(4);
                        handleCaseRating({
                          variables: { userId, caseId, rating: 4 }
                        });
                      }}
                    />
                    <StyledStarClick
                      icon={faStar}
                      size="2x"
                      onClick={() => {
                        handleStarClick(5);
                        handleCaseRating({
                          variables: { userId, caseId, rating: 5 }
                        });
                      }}
                    />
                  </StarContainer>
                )}
              </Mutation>
            );
          }}
        </Query>
      )}

      {rated && (
        <RatingFeedback>
          <StyledLabel>Thank you for the feedback</StyledLabel>
          <StyledLabel>
            This case currently has a{" "}
            <PaddedSpan>{renderCaseStars(updatedRating)}</PaddedSpan> rating
            based on {updatedRatingTotal} ratings
          </StyledLabel>
        </RatingFeedback>
      )}
    </RatingBlock>
  );
}

const RatingBlock = styled(CaseBlock)`
  ${props => !props.answered && "display:none;"}
`;

const PaddedSpan = styled.span`
  margin: 0 8px;
`;
const RatingFeedback = styled.div`
  text-align: center;
`;
const StarContainer = styled.div`
  text-align: center;
`;
const StyledStarClick = styled(FontAwesomeIcon)`
  color: lightgray;
  margin: 0 8px;
  cursor: pointer;
`;
const StyledStar = styled(FontAwesomeIcon)`
  color: lightgray;
`;
const StyledStarFull = styled(StyledStar)`
  color: #e67e22;
`;
const StyledStarHalf = styled(StyledStar)`
  color: #e67e22;
`;
const Loading = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 14px;
  text-align: center;
  color: gray;
`;
