import React, { useState, useEffect, Fragment, Suspense, lazy } from "react";
import { url } from "../settings";
import { Helmet } from "react-helmet";

import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";

// Dependencies
import { Route, Link } from "react-router-dom";

// Components
import CodeHealthEditorReadOnly from "./CodeHealthEditorReadOnly";
import CaseStem from "./CaseStem";
import CaseRating from "./CaseRating";
import CaseFooter from "./CaseFooter";
import CaseQuestionAnswer from "./CaseQuestionAnswer";
import CaseAuthor from "./CaseAuthor";
import Spinner from "../Spinner";
import Header from "../Components/Navigation/Header";

// Elements
import { CaseBlock, StyledLabel } from "../elements";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faChevronDown,
  faChevronUp,
  faAward
} from "@fortawesome/pro-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale, fontFamily } from "../utilities";
import { Button } from "../elements";

// Apollo, GraphQL
import { Query, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";

const CaseChoices = lazy(() => import("./CaseChoices"));
const CaseExplanation = lazy(() => import("./CaseExplanation"));
const RelatedCases = lazy(() => import("./RelatedCases"));

const GET_CASE = gql`
  query GET_CASE($hashid: String!) {
    case(hashid: $hashid) {
      id
      hashid
      isDraft
      isPremium
      caseCategory
      chiefComplaint
      clinicalScenario
      caseQuestion
      explanation
      caseTags
      casePhoto
      casePhotoThumb
      attempts
      correct
      incorrect
      layout
      caseType
      totalRating
      ratingAvg
      stemSet {
        id
        stem
      }
      questionSet {
        id
        question
      }
      caseExplanation {
        id
        explanation
      }
      caseAuthor {
        id
        username
        profile {
          bio
        }
      }
    }
  }
`;

const GET_ME = gql`
  query GET_ME {
    me {
      id
      username
      profile {
        subscriptionState
      }
    }
  }
`;

function CaseDetails(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedChoice, setSelectedChoice] = useState();
  function handleChoiceChange(event) {
    if (!answered) {
      setSelectedChoice(event.target.value);
    }
  }

  const [answered, setAnswered] = useState(false);
  const [correct, setCorrect] = useState();
  function handleAnswer(choices, layout) {
    window.scrollTo(0, 0);
    if (layout == "v3.0") {
      // 1. Get the correct answer choice
      const correctChoice = choices.find(choice => {
        return choice.correctAnswer === true;
      });

      // 2. Compare user's selected choice with the correct answer choice
      // 3. Set case state to 'answered'
      if (selectedChoice == correctChoice.id) {
        setCorrect(true);
      }
      setAnswered(true);
    } else {
      const correctChoice = choices.find(choice => {
        return choice.correctAnswer === true;
      });

      // 2. Compare user's selected choice with the correct answer choice
      // 3. Set case state to 'answered'
      if (selectedChoice == correctChoice.id) {
        setCorrect(true);
      }

      setAnswered(true);
    }
  }

  // when user selects a related case, resent casedetails states
  function handleRelatedCase() {
    setAnswered(false);
    setCorrect();
    setSelectedChoice();
  }

  function goBack() {
    props.history.goBack();
  }

  function createMarkup(html) {
    return { __html: html };
  }

  function createDraftText(caseObject) {
    if (caseObject.layout == "v3.0") {
      if (caseObject.caseType == "clinical scenario") {
        return caseObject.stemSet[0].stem;
      } else if (caseObject.caseType == "question") {
        return caseObject.questionSet[0].question;
      } else {
        return null;
      }
    }
  }

  function caseTitle(caseObject, answered, correct) {
    let caseTitleText;
    if (answered) {
      if (correct) {
        caseTitleText = "Correct";
      } else {
        caseTitleText = "Incorrect";
      }
    } else {
      caseTitleText = caseObject.chiefComplaint
        ? caseObject.chiefComplaint
        : "Untitiled Case";
    }
    return caseTitleText;
  }

  return (
    <Fragment>
      <Query
        query={GET_CASE}
        variables={{ hashid: props.match.params.caseHash }}
      >
        {({ loading, error, data }) => {
          if (loading) return null;
          if (!data)
            return (
              <CaseMainContainer answered={answered} correct={correct}>
                <Header />
                <CaseSubContainer>
                  <DoesNotExist>
                    <FontAwesomeIcon icon={faQuestionCircle} size="3x" />
                    <h2>Uh Oh! It looks like this case does not exist</h2>
                  </DoesNotExist>
                </CaseSubContainer>
              </CaseMainContainer>
            );

          const caseObj = data;
          const caseObject = data.case;
          return (
            <Query query={GET_ME}>
              {({ loading, error, data }) => {
                if (loading) return null;
                if (caseObject.isPremium) {
                  if (!data) {
                    // if user is NOT logged in
                    return (
                      <Fragment>
                        <Helmet>
                          <title>
                            {caseObject.chiefComplaint &&
                              caseObject.chiefComplaint}
                          </title>
                          <meta
                            property="og:title"
                            content={caseObject.chiefComplaint}
                          />
                          <meta
                            property="og:description"
                            content={caseObject.caseQuestion}
                          />
                          <meta
                            property="og:image"
                            content={caseObject.casePhoto}
                          />
                          <meta
                            name="twitter:image"
                            content={caseObject.casePhoto}
                          />
                          <meta
                            property="og:url"
                            content={
                              `https://codehealth.io/cases/`+caseObject.hashid
                            }
                          />
                          <meta property="og:site_name" content="CodeHealth/" />
                          <meta property="og:type" content="article" />
                          <meta
                            property="fb:app_id"
                            content="365480997233271"
                          />
                          <meta
                            name="twitter:card"
                            content="summary_large_image"
                          />
                          <meta name="twitter:site" content="@CodeHealthcare" />
                          <meta
                            name="twitter:title"
                            content={caseObject.chiefComplaint}
                          />
                          <meta
                            name="twitter:description"
                            content={caseObject.caseQuestion}
                          />
                        </Helmet>
                        <CaseMainContainer
                          answered={answered}
                          correct={correct}
                        >
                          <Header />
                          <CaseSubContainer>
                            <DoesNotExist>
                              <FontAwesomeIcon icon={faAward} size="3x" />
                              <h2>This is a Premium Case</h2>
                              <p>
                                To access this case,{" "}
                                <a href="https://codehealth.io/register/">
                                  sign-up for a CodeHealth account.
                                </a>
                              </p>
                            </DoesNotExist>
                          </CaseSubContainer>
                        </CaseMainContainer>
                      </Fragment>
                    );
                  } else if (!data.me.profile.subscriptionState) {
                    // logged in and NOT CodeHealth Member
                    return (
                      <CaseMainContainer answered={answered} correct={correct}>
                        <TitleCaseBlock answered={answered} correct={correct}>
                          <BackButton
                            onClick={() => goBack()}
                            answered={answered}
                          >
                            <FontAwesomeIcon icon={faAngleLeft} size="2x" />
                          </BackButton>
                        </TitleCaseBlock>
                        <CaseSubContainer>
                          <DoesNotExist>
                            <FontAwesomeIcon icon={faAward} size="3x" />
                            <h2>This is a Premium Case</h2>
                            <p>
                              To access this case,{" "}
                              <a href="https://codehealth.io/account/subscribe/">
                                sign-up for a premium account.
                              </a>
                            </p>
                          </DoesNotExist>
                        </CaseSubContainer>
                      </CaseMainContainer>
                    );
                  } else {
                    // logged in and IS CodeHealth Member
                    return (
                      <CaseMainContainer answered={answered} correct={correct}>
                        <TitleCaseBlock answered={answered} correct={correct}>
                          <BackButton
                            onClick={() => goBack()}
                            answered={answered}
                          >
                            <FontAwesomeIcon icon={faAngleLeft} size="2x" />
                          </BackButton>
                          <h2>{caseTitle(caseObject, answered, correct)}</h2>
                        </TitleCaseBlock>

                        <CaseSubContainer answered={answered}>
                          <MainCaseBlock>
                            <CaseStem
                              caseType={caseObject.caseType}
                              answered={answered}
                              caseLayout={caseObject.layout}
                              oldStem={createMarkup(
                                caseObject.clinicalScenario
                              )}
                              stem={createDraftText(caseObject)}
                              oldQuestion={createMarkup(
                                caseObject.clinicalScenario
                              )}
                              question={createDraftText(caseObject)}
                            />

                            <ChoicesBlock answered={answered} mobile={true}>
                              <CaseBlock>
                                <CaseQuestionAnswer
                                  caseObject={caseObject}
                                  createMarkup={createMarkup}
                                />
                                <Suspense
                                  fallback={<Loading>Loading....</Loading>}
                                >
                                  {caseObject.layout !== "v3.0" ? (
                                    <CaseChoices
                                      handleChoiceChange={handleChoiceChange}
                                      caseHash={props.match.params.caseHash}
                                      handleAnswer={handleAnswer}
                                      selectedChoice={selectedChoice}
                                      answered={answered}
                                      correct={correct}
                                    />
                                  ) : (
                                    <CaseChoices
                                      handleChoiceChange={handleChoiceChange}
                                      caseHash={props.match.params.caseHash}
                                      handleAnswer={handleAnswer}
                                      selectedChoice={selectedChoice}
                                      answered={answered}
                                      correct={correct}
                                    />
                                  )}
                                </Suspense>
                              </CaseBlock>
                            </ChoicesBlock>

                            <Suspense fallback={<Loading>Loading....</Loading>}>
                              <CaseExplanation
                                answered={answered}
                                caseHash={props.match.params.caseHash}
                                isLoggedIn={data}
                                caseObject={caseObject}
                              />
                            </Suspense>

                            {props.user && (
                              <CaseRating
                                caseObject={caseObject}
                                caseRating={caseObject.ratingAvg}
                                totalRating={caseObject.totalRating}
                                answered={answered}
                                caseId={caseObject.id}
                                user={props.user}
                              />
                            )}

                            <CaseAuthor
                              caseObject={caseObject}
                              answered={answered}
                            />
                          </MainCaseBlock>

                          <ChoicesBlock answered={answered}>
                            <CaseBlock>
                              <CaseQuestionAnswer
                                caseObject={caseObject}
                                createMarkup={createMarkup}
                              />
                              <Suspense
                                fallback={<Loading>Loading....</Loading>}
                              >
                                {caseObject.layout !== "v3.0" ? (
                                  <CaseChoices
                                    handleChoiceChange={handleChoiceChange}
                                    caseHash={props.match.params.caseHash}
                                    handleAnswer={handleAnswer}
                                    selectedChoice={selectedChoice}
                                    answered={answered}
                                    correct={correct}
                                  />
                                ) : (
                                  <CaseChoices
                                    handleChoiceChange={handleChoiceChange}
                                    caseHash={props.match.params.caseHash}
                                    handleAnswer={handleAnswer}
                                    selectedChoice={selectedChoice}
                                    answered={answered}
                                    correct={correct}
                                  />
                                )}
                              </Suspense>
                            </CaseBlock>
                          </ChoicesBlock>
                        </CaseSubContainer>

                        {answered && (
                          <Suspense fallback={<Spinner />}>
                            <RelatedCasesContainer>
                              <RelatedCases
                                category={caseObject.caseCategory}
                                history={props.history}
                                handleRelatedCase={handleRelatedCase}
                              />
                            </RelatedCasesContainer>
                          </Suspense>
                        )}

                        {props.user ? (
                          <CaseFooter
                            user={props.user}
                            caseHash={props.match.params.caseHash}
                            caseObject={caseObject}
                            answered={answered}
                          />
                        ) : (
                          !answered && <FooterContainer />
                        )}
                      </CaseMainContainer>
                    );
                  }
                } else {
                  return (
                    <Fragment>
                      <Helmet>
                        <title>
                          {caseObject.chiefComplaint &&
                            caseObject.chiefComplaint}
                        </title>
                        <meta
                          property="og:title"
                          content={caseObject.chiefComplaint}
                        />
                        <meta
                          property="og:description"
                          content={caseObject.caseQuestion}
                        />
                        <meta
                          property="og:image"
                          content={caseObject.casePhoto}
                        />
                        <meta
                          name="twitter:image"
                          content={caseObject.casePhoto}
                        />
                        <meta
                          property="og:url"
                          content={
                            `https://codehealth.io/cases/`+caseObject.hashid
                          }
                        />
                        <meta property="og:site_name" content="CodeHealth/" />
                        <meta property="og:type" content="article" />
                        <meta property="fb:app_id" content="365480997233271" />
                        <meta
                          name="twitter:card"
                          content="summary_large_image"
                        />
                        <meta name="twitter:site" content="@CodeHealthcare" />
                        <meta
                          name="twitter:title"
                          content={caseObject.chiefComplaint}
                        />
                        <meta
                          name="twitter:description"
                          content={caseObject.caseQuestion}
                        />
                      </Helmet>
                      <CaseMainContainer answered={answered} correct={correct}>
                        <TitleCaseBlock answered={answered} correct={correct}>
                          <BackButton
                            onClick={() => goBack()}
                            answered={answered}
                          >
                            <FontAwesomeIcon icon={faAngleLeft} size="2x" />
                          </BackButton>
                          <h2>{caseTitle(caseObject, answered, correct)}</h2>
                        </TitleCaseBlock>

                        <CaseSubContainer answered={answered}>
                          <MainCaseBlock>
                            <CaseStem
                              caseType={caseObject.caseType}
                              answered={answered}
                              caseLayout={caseObject.layout}
                              oldStem={createMarkup(
                                caseObject.clinicalScenario
                              )}
                              stem={createDraftText(caseObject)}
                              oldQuestion={createMarkup(
                                caseObject.clinicalScenario
                              )}
                              question={createDraftText(caseObject)}
                            />

                            <ChoicesBlock answered={answered} mobile={true}>
                              <CaseBlock>
                                <CaseQuestionAnswer
                                  caseObject={caseObject}
                                  createMarkup={createMarkup}
                                />
                                <Suspense
                                  fallback={<Loading>Loading....</Loading>}
                                >
                                  {caseObject.layout !== "v3.0" ? (
                                    <CaseChoices
                                      handleChoiceChange={handleChoiceChange}
                                      caseHash={props.match.params.caseHash}
                                      handleAnswer={handleAnswer}
                                      selectedChoice={selectedChoice}
                                      answered={answered}
                                      correct={correct}
                                    />
                                  ) : (
                                    <CaseChoices
                                      handleChoiceChange={handleChoiceChange}
                                      caseHash={props.match.params.caseHash}
                                      handleAnswer={handleAnswer}
                                      selectedChoice={selectedChoice}
                                      answered={answered}
                                      correct={correct}
                                    />
                                  )}
                                </Suspense>
                              </CaseBlock>
                            </ChoicesBlock>

                            <Suspense fallback={<Loading>Loading....</Loading>}>
                              <CaseExplanation
                                answered={answered}
                                caseHash={props.match.params.caseHash}
                                isLoggedIn={data}
                                caseObject={caseObject}
                              />
                            </Suspense>

                            {props.user && (
                              <CaseRating
                                caseObject={caseObject}
                                caseRating={caseObject.ratingAvg}
                                totalRating={caseObject.totalRating}
                                answered={answered}
                                caseId={caseObject.id}
                                user={props.user}
                              />
                            )}

                            <CaseAuthor
                              caseObject={caseObject}
                              answered={answered}
                            />
                          </MainCaseBlock>

                          <ChoicesBlock answered={answered}>
                            <CaseBlock>
                              <CaseQuestionAnswer
                                caseObject={caseObject}
                                createMarkup={createMarkup}
                              />
                              <Suspense
                                fallback={<Loading>Loading....</Loading>}
                              >
                                {caseObject.layout !== "v3.0" ? (
                                  <CaseChoices
                                    handleChoiceChange={handleChoiceChange}
                                    caseHash={props.match.params.caseHash}
                                    handleAnswer={handleAnswer}
                                    selectedChoice={selectedChoice}
                                    answered={answered}
                                    correct={correct}
                                  />
                                ) : (
                                  <CaseChoices
                                    handleChoiceChange={handleChoiceChange}
                                    caseHash={props.match.params.caseHash}
                                    handleAnswer={handleAnswer}
                                    selectedChoice={selectedChoice}
                                    answered={answered}
                                    correct={correct}
                                  />
                                )}
                              </Suspense>
                            </CaseBlock>
                          </ChoicesBlock>
                        </CaseSubContainer>

                        {answered && (
                          <Suspense fallback={<Spinner />}>
                            <RelatedCasesContainer>
                              <RelatedCases
                                category={caseObject.caseCategory}
                                history={props.history}
                                handleRelatedCase={handleRelatedCase}
                              />
                            </RelatedCasesContainer>
                          </Suspense>
                        )}

                        {props.user ? (
                          <CaseFooter
                            user={props.user}
                            caseHash={props.match.params.caseHash}
                            caseObject={caseObject}
                            answered={answered}
                          />
                        ) : (
                          !answered && <FooterContainer />
                        )}

                        {!props.user && (
                          <CallToAction>
                            For more cases like this & features, sign-up for a
                            CodeHealth account,{" "}
                            <a href="https://codehealth.io/register">here</a>
                          </CallToAction>
                        )}
                      </CaseMainContainer>
                    </Fragment>
                  );
                }
              }}
            </Query>
          );
        }}
      </Query>
    </Fragment>
  );
}
export default CaseDetails;

const RelatedCasesContainer = styled.div`
  margin-bottom: 150px;
`;
const CallToAction = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #333333;
  padding: 15px;
  color: white;
  font-family: futura-pt, sans-serif;
  box-sizing: border-box;
`;
const FooterContainer = styled.div`
  z-index: 20;
  padding: 18px 0 32px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f7f7f7;
  border-top: 1px solid #e7e7e7;
  @media (max-width: 768px) {
    height: 50px;
  }
`;
const CaseMainContainer = styled.div`
  position: absolute;
  top:0;
  left: 0
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: auto;
  background: ${grayscale.lightGray};
  a {
    color: ${purple.darkLight};
    ${props => props.correct && props.answered && `color: ${green.bright};`}
    ${props => !props.correct && props.answered && `color: ${red.bright};`}
  }
  .old-case-text {
      line-height: 30px;
      font-family: adobe-text-pro, serif;
      font-size: 18px;
  }
  .question {
      margin-bottom: 10px;
  }
  .public-DraftStyleDefault-block {
      margin-bottom: 10px;
  }
  @media (max-width: 576px) {
      .old-case-text{
          font-size: 15px;
          line-height:25px;
      }
  }
`;
const CaseSubContainer = styled.div`
  padding: 0px;
  max-width: 1100px;
  margin: auto;
  margin-top: 50px;
  ${props => !props.answered && `margin-bottom: 100px;`}
`;
const MainCaseBlock = styled.div`
  margin: 0 15px 0 11px;
  display: inline-block;
  width: 60%;
  vertical-align: top;
  @media (max-width: 992px) {
    width: 58.4%;
    margin: 0 10px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;
const ChoicesBlock = styled.div`
  display: inline-block;
  width: 36.5%;
  vertical-align: top;
  ${props => props.mobile && "display: none"}
  @media (max-width: 992px) {
    width: 38.4%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    ${props => props.mobile && "display: block"}
    ${props => !props.mobile && "display: none"}
  }
`;
const ExplanationCaseBlock = styled(CaseBlock)`
  display: none;
  ${props => props.answered && `display: block;`}
  img {
    width: 100%;
  }
`;
const TitleCaseBlock = styled(CaseBlock)`
  border-radius:0
  margin: 15px 0;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 0;
  z-index: 99;
  width: 100%;
  background: white;
  min-height: 30px;
  h2 {
    font-family: futura-pt, sans-serif;
    margin: 5px;
    max-width: 1100px;
    margin: auto;
    font-size:18px;
    @media (max-width: 1200px) {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      max-width: 76%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${props =>
    props.correct &&
    props.answered &&
    `background: ${green.bright}; color:white;`}
  ${props =>
    !props.correct &&
    props.answered &&
    `background: ${red.bright};color:white;`}
`;
const BackButton = styled.button`
  border: 0;
  color: black;
  padding: 0;
  outline: none;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  cursor: pointer;
  ${props => props.answered && `color: white;`}
  @media (max-width: 1200px) {
    display: inline-block;
    position: relative;
    margin: 0;
    transform: none;
    vertical-align: middle;
    left: 0;
    margin: 0 25px 0 15px;
  }
`;
const Loading = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 14px;
  text-align: center;
  color: gray;
`;
const DoesNotExist = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 50px 15px 15px 15px;
  text-align: center;
  font-family: ${fontFamily.sansSerif};
  h2 {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
`;
