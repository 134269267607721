import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { url } from "../../settings";

// Material UI
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { faLayerGroup } from "@fortawesome/pro-regular-svg-icons";
import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";
import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import { faListAlt } from "@fortawesome/pro-regular-svg-icons";
import { faClone } from "@fortawesome/pro-regular-svg-icons";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons";
import {
  faGraduationCap,
  faBookmark
} from "@fortawesome/pro-regular-svg-icons";

import styled from "styled-components";

// Apollo, GraphQL
import { Query, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";

const MY_CASESETS = gql`
  query MY_CASESETS($userId: Int!) {
    casesets(userId: $userId) {
      id
      setTitle
    }
  }
`;

const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      id
      username
    }
  }
`;

const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  subheader: {
    backgroundColor: "white"
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  menuLeft: {
    marginTop: 2,
    display: "inline-block"
  },
  menuButton: {
    fontSize: "inherit",
    minHeight: "inherit"
  }
};

class DrawerMenuLeft extends React.Component {
  state = {
    left: false,
    caseSets: []
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  render() {
    if (!this.state.caseSets) {
      return null;
    }

    const { classes } = this.props;

    return (
      <div className={classes.menuLeft}>
        <Button
          onClick={this.toggleDrawer("left", true)}
          className={classes.menuButton}
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </Button>

        <Drawer
          open={this.state.left}
          onClose={this.toggleDrawer("left", false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("left", false)}
            onKeyDown={this.toggleDrawer("left", false)}
          >
            <div className={classes.list}>
              <List>
                <Link to="/" className="menu-anchor">
                  <ListItem button>
                    <ListItemIconStyled>
                      <FontAwesomeIcon icon={faHome} />
                    </ListItemIconStyled>

                    <ListItemText disableTypography>
                      <span className="codehealth-font-family sm">Explore</span>
                    </ListItemText>
                  </ListItem>
                </Link>

                <Link to="/library" className="menu-anchor">
                  <ListItem button>
                    <ListItemIconStyled className="library-icon">
                      <FontAwesomeIcon icon={faBookmark} />
                    </ListItemIconStyled>

                    <ListItemText disableTypography>
                      <span className="codehealth-font-family sm">Library</span>
                    </ListItemText>
                  </ListItem>
                </Link>
              </List>

              <Divider />

              <List
                subheader={
                  <ListSubheader className={classes.subheader}>
                    <span className="codehealth-font-family sm">
                      <strong>Your Collections</strong>
                    </span>
                  </ListSubheader>
                }
              >
                <Query query={CURRENT_USER}>
                  {({ loading, data }) => {
                    if (loading) return <Loading>Loading...</Loading>;
                    const { me } = data;
                    return (
                      <Query query={MY_CASESETS} variables={{ userId: me.id }}>
                        {({ loading, data }) => {
                          if (loading) return <Loading>Loading...</Loading>;
                          const { casesets } = data;
                          if (casesets.length < 1) {
                            return (
                              <NoCaseSets>You have no collections</NoCaseSets>
                            );
                          } else {
                            return (
                              <Fragment>
                                {casesets.map(caseset => (
                                  <Link
                                    to={`/collection/${caseset.id}`}
                                    className="menu-anchor"
                                    key={caseset.id}
                                  >
                                    <ListItem button key={caseset.id}>
                                      <ListItemIconStyled>
                                        <FontAwesomeIcon icon={faClone} />
                                      </ListItemIconStyled>

                                      <ListItemText
                                        disableTypography
                                        className={classes.ellipsis}
                                      >
                                        <span className="codehealth-font-family sm">
                                          {caseset.setTitle}
                                        </span>
                                      </ListItemText>
                                    </ListItem>
                                  </Link>
                                ))}
                              </Fragment>
                            );
                          }
                        }}
                      </Query>
                    );
                  }}
                </Query>
              </List>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

DrawerMenuLeft.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DrawerMenuLeft);

const NoCaseSets = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 14px;
  text-align: center;
  color: gray;
`;
const Loading = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 14px;
  text-align: center;
  color: gray;
`;
const ListItemIconStyled = styled(ListItemIcon)`
  margin-right: 10px !important;
  &.library-icon {
    margin-left: 3px;
    margin-right: 14px !important;
  }
`;
