import React, { Suspense, lazy } from "react";

// Components
import PureEditorReadOnly from "../PureEditorReadOnly";
import CaseThumbnail from "./CaseThumbnail";
import CaseStars from "./CaseStars";
import CaseStats from "./CaseStats";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../utilities";

export default function CaseCardStandard(props) {
  const { cases, history, handleRelatedCase } = props;

  function handleCaseClick(id) {
    history.push(`/case/${id}`);
    handleRelatedCase && handleRelatedCase();
  }

  function createMarkup(html) {
    let htmlText = html;
    const charCount = html.length;
    if (charCount > 250) {
      htmlText = html.slice(0, charCount - 250) + "...";
    }

    return { __html: htmlText };
  }

  return (
    <HorizontalScrollContainer>
      <HorizontalScrollBlock>
        {cases.map(caseObject => {
          const { layout, hashid, id, caseType } = caseObject;
          return (
            <CaseCardStandardContainer
              key={caseObject.id}
              onClick={() => handleCaseClick(hashid || id)}
            >
              <Suspense fallback={<div />}>
                <CaseThumbnail
                  thumbnail={caseObject.casePhotoThumb || caseObject.casePhoto}
                  category={caseObject.caseCategory}
                  photoStyle={"horizontalScroll"}
                  case={caseObject}
                />
              </Suspense>

              <CaseType>{caseObject.caseType}</CaseType>

              {caseType === "question" ? (
                <div className="case-title">{caseObject.chiefComplaint}</div>
              ) : (
                <div className="case-title">{caseObject.chiefComplaint}</div>
              )}

              <CaseStars caseRating={caseObject.ratingAvg} />

              <CaseStats
                correct={caseObject.correct}
                incorrect={caseObject.incorrect}
              />
            </CaseCardStandardContainer>
          );
        })}
      </HorizontalScrollBlock>
    </HorizontalScrollContainer>
  );
}
const CaseCardStandardContainer = styled.div`
  display: inline-block;
  margin: 0 10px;
  margin-bottom: 50px;
  width: 22.7%;
  vertical-align: top;
  white-space: normal;
  cursor: pointer;
  img {
    object-fit: cover;
    height: 200px;
    width: 100%;
  }
  .old-case-text {
    p {
      margin: 0;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .case-title {
    font-weight: 700;
    font-size: 15px;
    font-family: futura-pt, sans-serif;
  }
  @media (max-width: 1200px) {
    width: 29%;
  }
  @media (max-width: 992px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 46%;
  }
  @media (max-width: 660px) {
    width: 60%;
  }
  @media (max-width: 576px) {
    width: 75%;
  }
  @media (max-width: 425px) {
    width: 88%;
  }
`;
const CaseType = styled.div`
  font-family: futura-pt, sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  margin-top: 5px;
  color: ${grayscale.lightDark};
`;
const HorizontalScrollContainer = styled.div`
  @media (max-width: 576px) {
    padding: 0;
  }
`;
const HorizontalScrollBlock = styled.div`
  max-width: none;
  padding: 0 15px;
  font-family: futura-pt, sans-serif;
  max-width: 1300px
  margin: auto;
  @media (max-width: 1300px) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 576px) {
      padding: 15px 10px;
  }
  p {
    white-space: normal;
  }
`;
