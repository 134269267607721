import React, { Fragment } from "react";

// Styles
import styled from "styled-components";
import { Button } from "./elements";

export default function FeaturedCase(props) {
  const { featCaseDesktop, featCaseMobile, textColor, textAlign } = props;

  function handleCaseClick(caseHash) {
    props.history.push(`/case/${caseHash}`);
  }
  return (
    <FeaturedCaseContainer>
      <img src={featCaseDesktop} className="desktop" />
      <img src={featCaseMobile} className="mobile" />

      <div className="text-container">
        <div className="text-block">
          <p>Featured Case</p>
          <h2 className="desktop">
            Difficulty Breathing & <br /> A New Heart Murmur
            <p className="sub-text">
              An elderly man complains of trouble breathing. He was recently
              hospitalized for an inferior wall myocardial infarction. On exam,
              he has diffuse rales on auscultation of his lungs and a new
              holosystolic murmur that is loudes at the apex and radiates to the
              axilla.
            </p>
          </h2>
          <h2 className="mobile">
            Difficulty <br /> Breathing & <br /> A New Heart
            <br /> Murmur
          </h2>

          <FeaturedButton onClick={() => handleCaseClick("GRb4kgaB")}>
            ANSWER CASE
          </FeaturedButton>
        </div>
      </div>
    </FeaturedCaseContainer>
  );
}

const FeaturedCaseContainer = styled.div`
  height: 575px;
  position: relative;
  margin-bottom: 50px;
  img {
    width: 100%;
    object-fit: cover;
    height: 575px;
    position: absolute;
    &.mobile {
      display: none;
    }
  }
  .text-container {
    position: absolute;
    width: 100%;
    top: 45%;
    transform: translateY(-50%);
  }
  .text-block {
    max-width: 1200px;
    margin: auto;
    padding: 50px 25px;
    font-family: futura-pt, sans-serif;
    font-size: 24px;
    color: white;
    h2 {
      margin-bottom: 0;
      position: relative;
      top: 0;
      p {
           font-size: 16px;
           max-width: 450px;
           font-weight: 300;
      }
      &.sub-text {
          margin-top: 15px;
      }
      &.mobile {
        display: none;
      }
    }
    p {
      margin-top: 0;
      position: relative;
    }

    @media (max-width: 992px) {
      padding: 50px 95px;
    }
    @media (max-width: 850px) {
      padding: 50px 70px;
    }
    @media (max-width: 800px) {
      padding: 50px 30px;
    }
    @media (max-width: 576px) {
      padding: 15px;
      p {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 640px) {
    height: 450px;
    .text-block {
      h2 {
        &.mobile {
          display: block;
          top: 0;
        }
        &.desktop {
          display: none;
        }
      }
    }

    img {
      height: 450px;
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
  }
`;

const FeaturedButton = styled(Button)`
  position: relative;
  top: 15px;
  background: white;
  color: black;
  padding: 15px 56px;
  @media (max-width: 640px) {
    top: 15px;
    padding: 15px 30px;
  }
`;
