import React, { Component, Fragment, useState, useEffect } from "react";
import { url } from "../settings";

// Components

// Dependencies
import { Route, Link } from "react-router-dom";
import axios from "axios";

// Styles
import styled from "styled-components";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/pro-solid-svg-icons";

function CaseStars(props) {
  const [caseRating, setCaseRating] = useState(0);

  useEffect(() => {
    const rating = props.caseRating;
    if (rating <= 0.5) {
    }
  });

  function renderCaseStars(rating) {
    if (rating < 0.5) {
      return (
        <Fragment>
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
        </Fragment>
      );
    } else if (rating >= 0.5 && rating < 1) {
      return (
        <Fragment>
          <StyledStarHalf icon={faStarHalf} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
        </Fragment>
      );
    } else if (rating >= 1 && rating < 1.5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
        </Fragment>
      );
    } else if (rating >= 1.5 && rating < 2) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarHalf icon={faStarHalf} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
        </Fragment>
      );
    } else if (rating >= 2 && rating < 2.5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
        </Fragment>
      );
    } else if (rating >= 2.5 && rating < 3) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarHalf icon={faStarHalf} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
        </Fragment>
      );
    } else if (rating >= 3 && rating < 3.5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
        </Fragment>
      );
    } else if (rating >= 3.5 && rating < 4) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarHalf icon={faStarHalf} size="xs" />
          <StyledStar icon={faStar} size="xs" />
        </Fragment>
      );
    } else if (rating >= 4 && rating < 4.5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStar icon={faStar} size="xs" />
        </Fragment>
      );
    } else if (rating >= 4.5 && rating < 5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarHalf icon={faStarHalf} size="xs" />
        </Fragment>
      );
    } else if (rating = 5) {
      return (
        <Fragment>
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
          <StyledStarFull icon={faStar} size="xs" />
        </Fragment>
      );
    }
  }

  return <Fragment>{renderCaseStars(props.caseRating)}</Fragment>;
}
const StyledStar = styled(FontAwesomeIcon)`
  color: lightgray;
  font-size: 11px;
`;
const StyledStarFull = styled(StyledStar)`
  color: #e67e22;
`;
const StyledStarHalf = styled(StyledStar)`
  color: #e67e22;
`;

export default CaseStars;
