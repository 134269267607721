import React, { Component, Fragment, useState, Suspense, lazy } from "react";
import featCaseMobile from "./Static/featured_case_mobile.jpg";
import featCaseDesktop from "./Static/featured_case_wide.jpg";

import { url, grandrounds } from "./settings";

// Components
import CaseCard from "./CaseCard/CaseCard";
import GrandRounds from "./GrandRounds";
import FeaturedCase from "./FeaturedCase";
import MoreCases from "./MoreCases";
import Header from "./Components/Navigation/Header";
import TabNavigator from "./Components/Navigation/TabNavigator";
import Spinner from "./Spinner";

// Dependencies
import { Route } from "react-router-dom";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "./utilities";
import "./fonts/medzcool-glyphicons.css";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";

// Apollo, GraphQL
import { Query, Mutation, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";

const CategoryCases = lazy(() => import("./CategoryCases"));
const PriorFeaturedCase = lazy(() => import("./PriorFeaturedCase"));

const CASE_FRAGMENT = gql`
  fragment CaseData on CaseType {
    id
    hashid
    caseCategory
    chiefComplaint
    clinicalScenario
    caseQuestion
    explanation
    caseTags
    casePhoto
    casePhotoThumb
    attempts
    correct
    incorrect
    layout
    caseType
    totalRating
    ratingAvg
    isPremium
    isDraft
    stemSet {
      id
      stem
    }
    questionSet {
      id
      question
    }
  }
`;

const ALL_CASES = gql`
  query ALL_CASES ($skip: Int, $last: Int) {
    randomCases: cases(randomize:true, count:20, isDraft:"False") {
      ...CaseData
    }
    grandrounds: cases(caseset: ${grandrounds}, count: 4) {
        ...CaseData
    }
    newCases: cases(last: $last, skip: $skip, isDraft: "False") {
      ...CaseData
    }
  }
  ${CASE_FRAGMENT}
`;

export default function Homepage(props) {
  const [caseTarget, setCaseTarget] = useState(false);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [newCasesLength, setNewCasesLength] = useState(4);

  function handleCaseClick(caseHash) {
    props.history.push(`/case/${caseHash}`);
  }

  function createMarkup(html) {
    return { __html: html };
  }

  if (!props.responseData) {
    return <div>Loading...</div>;
  } else {
    return (
      <Fragment>
        <Header history={props.history} />
        <Content>
          <FeaturedCase
            history={props.history}
            featCaseMobile={featCaseMobile}
            featCaseDesktop={featCaseDesktop}
          />
          <Query
            query={ALL_CASES}
            variables={{
              last: 16
            }}
            notifyOnNetworkStatusChange={true}
            fetchPolicy="cache-and-network"
          >
            {({ loading, data, fetchMore, networkStatus }) => {
              if (networkStatus == 1) return <Spinner />;

              const { grandrounds, randomCases, newCases } = data;

              const randomFirst = randomCases.slice(0, 8);
              const randomSecond = randomCases.slice(8, 20);

              return (
                <Fragment>
                  <HomepageContainer>
                    <CasesContainer>
                      {randomFirst.map(caseObject => {
                        return (
                          <CaseCard
                            mobile={"expanded"}
                            desktop={"standard"}
                            caseObject={caseObject}
                            history={props.history}
                            key={caseObject.id}
                          />
                        );
                      })}
                    </CasesContainer>
                  </HomepageContainer>

                  <GrandRounds
                    cases={grandrounds}
                    handleCaseClick={handleCaseClick}
                  />

                  <HomepageContainer noTitle={"true"}>
                    <CasesContainer>
                      {randomSecond.map(caseObject => {
                        return (
                          <CaseCard
                            mobile={"expanded"}
                            desktop={"standard"}
                            caseObject={caseObject}
                            history={props.history}
                            key={caseObject.id}
                          />
                        );
                      })}
                    </CasesContainer>
                  </HomepageContainer>

                </Fragment>
              );
            }}
          </Query>
        </Content>
      </Fragment>
    );
  }
}
const CasesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const Content = styled.div`
  top: 50px;
  position: relative;
  padding-bottom: 75px;
  @media (max-width: 786px) {
    h2 {
      font-size: 26px !important;
    }
    padding-bottom: 85px;
    background: #f2f2f2;
  }
`;
const BlockHeadings = styled.div`
  font-family: futura-pt, sans-serif;
  margin-bottom: 35px;
  padding: 0 15px;
  h2 {
    font-size: 33px;
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
  }
  @media (max-width: 576px) {
    margin: 0;
    padding-bottom: 35px;
  }
`;
const CaseCardContainer = styled.div`
  display: inline-block;
  margin: 0 10px;
  margin-bottom: 50px;
  width: 22.7%;
  vertical-align: top;
  white-space: normal;
  cursor: pointer;
  .old-case-text {
    p {
      margin: 0;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .case-title {
    font-weight: 700;
    font-size: 15px;
    font-family: futura-pt, sans-serif;
  }
`;
const CaseType = styled.div`
  font-family: futura-pt, sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  margin-top: 5px;
  color: ${grayscale.lightDark};
`;
const CaseOfMonth = styled.div`
  height: 400px;
  background: purple;
`;
const HomepageContainer = styled.div`
  max-width: 1300px;
  margin: auto;
  padding: 0px 8px 50px 15px;
  ${props => props.noTitle && `padding-top: 100px;`}
  @media (max-width: 576px) {
    padding: 15px 0;
    background: #f2f2f2;
  }

  ${CaseCardContainer} {
    .mobile {
      display: none;
      .mobile-block {
        padding: 15px;
      }
    }
    @media (max-width: 992px) {
      width: 47%;
    }
    @media (max-width: 800px) {
      width: 46%;
    }
    @media (max-width: 576px) {
      width: 100%;
      margin: 5px 0;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      padding: 0;
      background: white;
      .desktop {
        display: none;
      }
      .mobile {
        display: block;
        img {
          height: 200px;
        }
      }
    }
  }
`;
