import React from "react";
import PropTypes from "prop-types";
import { url } from "../../settings";

//Material UI
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-regular-svg-icons";

import styled from "styled-components";

const styles = {
  button: {
    height: 40,
    width: 40,
    padding: 0
  }
};

class UserAvatar extends React.Component {
  fetchUser = () => {
    this.props.toggleDrawer("right", true);
    console.log("clicked avatar");
    this.props.clickAvatar();
  };

  render() {
    const { classes } = this.props;

    return (
      <UserAvatarStyle>
        <IconButton className={classes.button} onClick={this.fetchUser}>
          <FontAwesomeIcon className="avatar" icon={faUserCircle} size="lg" />
        </IconButton>
      </UserAvatarStyle>
    );
  }
}

UserAvatar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserAvatar);

const UserAvatarStyle = styled.div`
  display: block;
  .avatar {
    color: black
  }
  @media (max-width: 576px) {
    display: none;
  }
`;
