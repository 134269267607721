import React, { Fragment } from "react";

// Elements
import { CaseBlock, StyledLabel } from "../elements";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons";

// Styles
import styled from "styled-components";

export default function CaseAuthor(props) {
  const { caseAuthor } = props.caseObject;

  if (caseAuthor.profile.bio) {
    return (
      <AuthorBlock answered={props.answered}>
        <UserProfileText>
        <StyledLabel>Author</StyledLabel>
          <h3>{caseAuthor.username}</h3>
          <p>{caseAuthor.profile.bio}</p>
        </UserProfileText>
      </AuthorBlock>
    );
  } else {
    return null;
  }
}

const AuthorBlock = styled(CaseBlock)`
  font-family: futura-pt, sans-serif;
  line-height: 1.1;
  h3,
  p {
    margin: 0;
  }
  ${StyledLabel} {
    margin-bottom: 0;
  }
  ${props => !props.answered && "display:none;"}
`;
const UserIcon = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
`;
const UserProfileText = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
