import React, { Fragment, useEffect, useState } from "react";
import debounce from "lodash/debounce";

// Components
import CaseCard from "./CaseCard/CaseCard";
import Spinner from "./Spinner";

// Styles
import styled from "styled-components";
import { grayscale } from "./utilities";
import { Button } from "./elements";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const CASE_FRAGMENT = gql`
  fragment CaseData on CaseType {
    id
    hashid
    caseCategory
    chiefComplaint
    clinicalScenario
    caseQuestion
    explanation
    caseTags
    casePhoto
    casePhotoThumb
    attempts
    correct
    incorrect
    layout
    caseType
    totalRating
    ratingAvg
    stemSet {
      id
      stem
    }
    questionSet {
      id
      question
    }
  }
`;

export default function MoreCases(props) {
  const { networkStatus } = props;

  const [loading, setLoading] = useState(false);
  useEffect(
    () => {
      if (networkStatus == 3) {
        setLoading(true);
      }
      if (networkStatus == 7) {
        setLoading(false);
      }
      return () => {};
    },
    [networkStatus]
  );

  function createMarkup(html) {
    return { __html: html };
  }

  // useEffect(() => {
  //   window.addEventListener("scroll", handleOnScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleOnScroll);
  //   };
  // }, []);
  const handleOnScroll = debounce(function() {
    console.log("scrolling", networkStatus);
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    var scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    const offsetBottom = 1000;
    var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom && !loading) {
      props.onLoadMore();
    }
  }, 1000);

  function handleOnScroll() {}

  return (
    <Fragment>
      <CasesContainer>
        {props.cases.map(caseObject => {
          return (
            <CaseCard
              mobile={"expanded"}
              desktop={"standard"}
              caseObject={caseObject}
              history={props.history}
              key={caseObject.id}
            />
          );
        })}
      </CasesContainer>

      <ButtonContainer>
        {networkStatus == 3 ? (
          <Spinner />
        ) : (
          <ButtonStyled onClick={props.onLoadMore}>LOAD MORE</ButtonStyled>
        )}
      </ButtonContainer>
    </Fragment>
  );
}
const CasesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ButtonContainer = styled.div`
  text-align: center;
  margin: 35px;
`;
const ButtonStyled = styled(Button)`
  width: 100%;
  max-width: 500px;
`;
const BlockHeadings = styled.div`
  font-family: futura-pt, sans-serif;
  margin-bottom: 35px;
  padding: 0 15px;
  h2 {
    font-size: 33px;
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
  }
`;
const CaseCardContainer = styled.div`
  display: inline-block;
  margin: 0 10px;
  margin-bottom: 50px;
  width: 22.7%;
  vertical-align: top;
  white-space: normal;
  cursor: pointer;
  img {
    object-fit: cover;
    height: 170px;
    width: 100%;
  }
  .old-case-text {
    p {
      margin: 0;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .case-title {
    font-weight: 700;
    font-size: 15px;
    font-family: futura-pt, sans-serif;
  }
`;
const CaseType = styled.div`
  font-family: futura-pt, sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  margin-top: 5px;
  color: ${grayscale.lightDark};
`;
const HomepageContainer = styled.div`
  max-width: 1300px;
  margin: auto;
  padding: 50px 8px 50px 15px;
  @media (max-width: 992px) {
    padding: 50px 70px 50px 90px;
  }
  @media (max-width: 850px) {
    padding: 50px 50px 50px 65px;
  }
  @media (max-width: 800px) {
    padding: 50px 0px 50px 25px;
  }
  @media (max-width: 576px) {
    padding: 15px 0;
    background: #f2f2f2;
  }

  ${CaseCardContainer} {
    .mobile {
      display: none;
      .mobile-block {
        padding: 15px;
      }
    }
    @media (max-width: 992px) {
      width: 47%;
    }
    @media (max-width: 800px) {
      width: 46%;
    }
    @media (max-width: 576px) {
      width: 100%;
      margin: 5px 0;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      padding: 15px 0;
      background: white;
      .desktop {
        display: none;
      }
      .mobile {
        display: block;
        img {
          height: 200px;
        }
      }
    }
  }
`;
