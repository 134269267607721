import React, { Fragment, Component } from "react";

// Components
import CodeHealthEditorReadOnly from "./CodeHealthEditorReadOnly";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../utilities";

// Apollo, GraphQL
import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";

const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      id
      username
    }
  }
`;

const ANSWER_CASE = gql`
  mutation ANSWER_CASE($userId: Int, $selectedChoiceId: Int!, $caseId: Int!) {
    answerCase(
      userId: $userId
      selectedChoiceId: $selectedChoiceId
      caseId: $caseId
    ) {
      case {
        id
        attempts
        correct
        incorrect
      }
      choice {
        id
        choiceTextfield
        votes
      }
    }
  }
`;

function CaseAnswerButton(props) {
  const { caseObject } = props;
  function handleCaseAnswer() {
    const layout = caseObject.layout;
    if (layout !== "v3.0") {
      const choices = caseObject.oldChoices;
      props.handleAnswer(choices, layout);
    } else {
      const choices = caseObject.questionSet[0].choices;
      props.handleAnswer(choices, layout);
    }
  }

  const selectedChoiceId = props.selectedChoice;
  const caseId = caseObject.id;
  return (
    <Query query={CURRENT_USER}>
      {({ loading, data }) => {
        if (loading) return <Loading>Loading...</Loading>;
        if (data) {
            const { me } = data;
            const userId = me.id;
            return (
              <Mutation mutation={ANSWER_CASE}>
                {answerCase => (
                  <Fragment>
                    {props.correctAnswerCreated ? (
                      <Fragment>
                        <FullSizeButton
                          disabled={!props.selectedChoice}
                          onClick={() => {
                            answerCase({
                              variables: { userId, selectedChoiceId, caseId }
                            });
                            handleCaseAnswer();
                          }}
                          answered={props.answered}
                        >
                          {props.selectedChoice
                            ? "SUBMIT ANSWER"
                            : "SELECT AN ANSWER CHOICE"}
                        </FullSizeButton>
                        <MobileButtonContainer answered={props.answered}>
                          <MobileButton
                            disabled={!props.selectedChoice}
                            onClick={() => {
                              answerCase({
                                variables: { userId, selectedChoiceId, caseId }
                              });
                              handleCaseAnswer();
                            }}
                            answered={props.answered}
                          >
                            {props.selectedChoice
                              ? "SUBMIT ANSWER"
                              : "SELECT AN ANSWER CHOICE"}
                          </MobileButton>
                        </MobileButtonContainer>
                      </Fragment>
                    ) : (
                      <InfoText>
                        You have not yet selected a correct answer
                      </InfoText>
                    )}
                  </Fragment>
                )}
              </Mutation>
            )
        } else {
            const userId = 0
            return (
              <Mutation mutation={ANSWER_CASE}>
                {answerCase => (
                  <Fragment>
                    {props.correctAnswerCreated ? (
                      <Fragment>
                        <FullSizeButton
                          disabled={!props.selectedChoice}
                          onClick={() => {
                            answerCase({
                              variables: { userId, selectedChoiceId, caseId }
                            });
                            handleCaseAnswer();
                          }}
                          answered={props.answered}
                        >
                          {props.selectedChoice
                            ? "SUBMIT ANSWER"
                            : "SELECT AN ANSWER CHOICE"}
                        </FullSizeButton>
                        <MobileButtonContainer answered={props.answered}>
                          <MobileButton
                            disabled={!props.selectedChoice}
                            onClick={() => {
                              answerCase({
                                variables: { userId, selectedChoiceId, caseId }
                              });
                              handleCaseAnswer();
                            }}
                            answered={props.answered}
                          >
                            {props.selectedChoice
                              ? "SUBMIT ANSWER"
                              : "SELECT AN ANSWER CHOICE"}
                          </MobileButton>
                        </MobileButtonContainer>
                      </Fragment>
                    ) : (
                      <InfoText>
                        You have not yet selected a correct answer
                      </InfoText>
                    )}
                  </Fragment>
                )}
              </Mutation>
            )
        }

      }}
    </Query>
  );
}
export default CaseAnswerButton;

const FullSizeButton = styled.button`
  width: 100%;
  border: 0;
  border-radius: 4px;
  background-color: #ffc820;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  color: ${purple.darkDark};
  padding: 15px;
  outline: none;
  font-family: futura-pt, sans-serif;
  cursor: pointer;
  &:disabled {
    background: lightgray;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    color: gray;
    transform: translateY(-3px);
  }
  ${props => props.answered && `display: none;`}
  @media (max-width: 768px) {
    display: none;
  }
`;
const MobileButtonContainer = styled.div`
  position: Fixed;
  bottom: 45px;
  left: 0;
  z-index: 99;
  text-align: center;
  width: 100%;
  display: none;
  @media (max-width: 768px) {
    display: block;
    ${props => props.answered && `display: none;`}
  }
`;
const MobileButton = styled.button`
  position: relative;
  border: 0;
  border-radius: 4px;
  color: ${purple.darkDark};
  padding: 15px;
  outline: none;
  font-family: futura-pt, sans-serif;
  cursor: pointer;
  width: 95%;
  background-color: #ffc820;
  &:disabled {
    background: lightgray;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    color: #747474;
  }
`;
const InfoText = styled.div`
  color: gray;
  font-style: italic;
  font-family: adobe-text-pro, serif;
  text-align: center;
`;

const Loading = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 14px;
  text-align: center;
  color: gray;
`;
