import React, { Fragment } from "react";

// Elements
import { StyledLabel } from "../elements";

// Components
import CodeHealthEditorReadOnly from "./CodeHealthEditorReadOnly";

function CaseQuestionAnswer(props) {
  const { caseObject, createMarkup } = props;

  let questionLayout;
  if (caseObject.layout !== "v3.0") {
    if (caseObject.caseType == "question") {
      questionLayout = <StyledLabel>Answer Choices</StyledLabel>;
    } else {
      questionLayout = (
        <Fragment>
          <StyledLabel>Answer Choices</StyledLabel>
          <div
            className="old-case-text question"
            dangerouslySetInnerHTML={createMarkup(caseObject.caseQuestion)}
          />
        </Fragment>
      );
    }
  } else {
    if (caseObject.caseType == "question") {
      questionLayout = <StyledLabel>Answer Choices</StyledLabel>;
    } else {
      questionLayout = (
        <Fragment>
          <CodeHealthEditorReadOnly
            label="Answer Choices"
            propsEditorState={caseObject.questionSet[0].question}
          />
        </Fragment>
      );
    }
  }

  return questionLayout;
}

export default CaseQuestionAnswer;
