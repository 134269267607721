import React, { Fragment, Component, useState } from "react";
import { url } from "../settings";

// Components
import InteractFavorite from "./InteractFavorite";
import InteractiveCaseSet from "./InteractiveCaseSet";
import CaseAnswerButton from "./CaseAnswerButton";

// Elements
import { CaseBlock } from "../elements";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/pro-solid-svg-icons";
import {
  faHeart,
  faPencil,
  faShare,
  faClone
} from "@fortawesome/pro-regular-svg-icons";

// Styles
import styled from "styled-components";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      id
      username
    }
  }
`;

function CaseFooter(props) {
  function handleEditClick() {}
  return (
    <Query query={CURRENT_USER}>
      {({ loading, data }) => {
        if (loading) return <Loading>Loading...</Loading>;
        const { me } = data;
        const user = me;
        return (
          <FooterContainer>
            <FootWrapper answered={props.answered}>
              {props.caseObject.caseAuthor.id == user.id && (
                <FooterBlock>
                  <a
                    href={`https://codehealth.io/cases/editing/${
                      props.caseObject.id
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={faPencil}
                      size="lg"
                      onClick={handleEditClick}
                    />
                    <span>EDIT</span>
                  </a>
                </FooterBlock>
              )}

              <InteractFavorite
                user={props.user}
                caseHash={props.caseHash}
                caseObject={props.caseObject}
              />
              <InteractiveCaseSet user={user} caseObject={props.caseObject} />
            </FootWrapper>
          </FooterContainer>
        );
      }}
    </Query>
  );
}
export default CaseFooter;

const FooterBlock = styled.div`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 0 25px;
  span {
    font-family: futura-pt, sans-serif;
    margin-left: 8px;
  }
  a {
    text-decoration: none;
    color: black !important;
  }
  @media (max-width: 992px) {
    padding: 0;
    min-width: 20%;
    flex: 1 1 auto;
    position: relative;
    span {
      display: block;
      padding-top: 5px;
      margin-left: 0 !important;
      font-size: 8px;
      position: absolute;
      width: 100%;
      text-align: center;
    }
  }
`;
const FootWrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  @media (max-width: 992px) {
    padding: 5px 0 20px;
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    display: none;
    ${props => props.answered && `display: flex;`}
  }
`;
const FooterContainer = styled.div`
  z-index: 20;
  padding: 18px 0 32px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f7f7f7;
  border-top: 1px solid #e7e7e7;
  @media (max-width: 768px) {
    height: 50px;
  }
`;
const PaddedSpan = styled.span`
  margin: 0 8px;
`;
const Loading = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 14px;
  text-align: center;
  color: gray;
`;
