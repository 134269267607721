import React, { Suspense, lazy } from "react";

// Components
import PureEditorReadOnly from "../PureEditorReadOnly";
import CaseThumbnail from "./CaseThumbnail";
import CaseStars from "./CaseStars";
import CaseStats from "./CaseStats";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

export default function CaseCardStandard(props) {
  const { caseObject, history } = props;
  const { layout, hashid, id, caseType } = caseObject;

  function handleCaseClick(id) {
    history.push(`/case/${id}`);
  }

  function createMarkup(html) {
    let htmlText = html;
    const charCount = html.length;
    if (charCount > 250) {
      htmlText = html.slice(0, charCount - 250) + "...";
    }

    return { __html: htmlText };
  }

  return (
    <CaseCardListContainer onClick={() => handleCaseClick(hashid || id)}>
      <Suspense fallback={<div />}>
        <CaseThumbnail
          thumbnail={caseObject.casePhotoThumb || caseObject.casePhoto}
          category={caseObject.caseCategory}
          photoStyle={"list"}
          case={caseObject}
        />
      </Suspense>

      <CaseCardRight>
        <div>
          <CaseType>{caseObject.caseType}</CaseType>

          {caseType === "question" ? (
            <div className="case-title">{caseObject.chiefComplaint}</div>
          ) : (
            <div className="case-title">{caseObject.chiefComplaint}</div>
          )}

          <CaseStars caseRating={caseObject.ratingAvg} />

          <CaseStats
            correct={caseObject.correct}
            incorrect={caseObject.incorrect}
          />
        </div>
        <div className="chevron-container">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </CaseCardRight>
    </CaseCardListContainer>
  );
}
const CaseCardListContainer = styled.div`
  display: none;
  margin: 0 10px;
  vertical-align: top;
  white-space: normal;
  cursor: pointer;
  height: 130px;
  border-bottom: 1px solid ${grayscale.lightGray};
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .old-case-text {
    p {
      margin: 0;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .case-title {
    font-weight: 700;
    font-size: 15px;
    font-family: futura-pt, sans-serif;
  }
  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: 120px auto;
  }
  @media (max-width: 768px) {
    margin: 0;
    height: 115px;
    .case-title {
      font-size: 14px;
    }
  }
  @media (max-width: 625px) {
    grid-template-columns: 110px auto;
  }
`;
const CaseCardLeft = styled.div``;
const CaseCardRight = styled.div`
  padding: 15px;
  padding-right: 0;
  display: grid;
  grid-template-columns: auto 50px;
  .chevron-container {
    align-self: center;
    text-align: center;
  }
  @media (max-width: 768px) {
    padding: 6px 0px 6px 15px;
  }
  @media (max-width: 425px) {
    padding: 6px 0px 6px 10px;
    grid-template-columns: auto 35px;
  }
`;
const CaseType = styled.div`
  font-family: futura-pt, sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  margin-top: 5px;
  color: ${grayscale.lightDark};
`;
