import styled from "styled-components";

export const CaseBlock = styled.div`
  margin: 15px 0;
  background: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 25px;
  position: relative;
  @media (max-width: 768px) {
      border-radius: 0;
  }
`;
export const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  align: left;
  margin-bottom: 15px;
`;
