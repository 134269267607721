import React, {
  Fragment,
  Component,
  useState,
  useContext,
  useEffect
} from "react";
import { url } from "../settings";

// Dependencies
import axios from "axios";

// Components
import CaseSetDialog from "../UI/CaseSetDialog";

// Context
import { CaseDetailsContext } from "../Context/CaseDetailsContext";
import { UserContext } from "../Context/UserContext";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone as fasClone } from "@fortawesome/pro-solid-svg-icons";
import { faClone } from "@fortawesome/pro-regular-svg-icons";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../utilities";


export default function InteractiveCaseSet(props) {

  const [casesets, setCasesets] = useState();
  function handleCaseSetClick() {
    setDialogOpen(true);
    setCasesets(true);
  }

  const [dialogOpen, setDialogOpen] = useState(false);
  function closeDialog() {
    setDialogOpen(false);
  }

  const [inCaseSet, setInCaseSet] = useState(false);

  const userId = props.user.id;
  const caseId = props.caseObject.id;

  return (
    <Fragment>
      <FooterBlock isFavorited={inCaseSet}>
        <div onClick={handleCaseSetClick}>
          {inCaseSet ? (
            <FontAwesomeIcon icon={fasClone} size="lg" />
          ) : (
            <FontAwesomeIcon icon={faClone} size="lg" />
          )}
          <span>ADD CASE SET</span>
        </div>
        {casesets && (
          <CaseSetDialog
            open={dialogOpen}
            dialogTitle={"Add to Case Set"}
            dialogText={"Add this case to a case set or create a new case set"}
            dialogButton="close"
            closeDialog={closeDialog}
            userId={userId}
            caseId={caseId}
          />
        )}
      </FooterBlock>
    </Fragment>
  );
};

const FooterBlock = styled.div`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 0 25px;
  span {
    font-family: futura-pt, sans-serif;
    margin-left: 8px;
  }
  ${props => props.isFavorited && `color:${red.bright}`}
  @media (max-width: 992px) {
    padding: 0;
    min-width: 20%;
    flex: 1 1 auto;
    position: relative;
    span {
      display: block;
      padding-top: 5px;
      margin-left: 0 !important;
      font-size: 8px;
      position: absolute;
      width: 100%;
      text-align: center;
    }
  }
`;
