import styled from "styled-components";

export const HeaderComponent = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px;
  @media (max-width: 576px) {
    ${props => props.type == "avatar" && `display:none;`}
  }
`;
