import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { url } from "../../settings";

// Components
import DrawerMenuRight from "./DrawerMenuRight";

// Material UI
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUser, faBookmark } from "@fortawesome/pro-regular-svg-icons";
import {
  faHome as fasHome,
  faUser as fasUser,
  faBookmark as fasBookmark
} from "@fortawesome/pro-solid-svg-icons";
import {
  faUserCircle,
  faCog,
  faFolder,
  faPlus,
  faChartLine,
  faSignOut,
  faAward,
  faQuestionSquare
} from "@fortawesome/pro-regular-svg-icons";

// Styles
import styled from "styled-components";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_ME = gql`
  query GET_ME {
    me {
      id
      username
    }
  }
`;

const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  smText: {
    fontSize: 10
  },
  subheader: {
    backgroundColor: "white"
  },
  avatarContainer: {
    display: "inline-block"
  },
  menuAnchor: {
    color: "#333",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    }
  }
};

function TabNavigator(props) {
  const { location } = props;
  const { classes } = props;

  const [right, setRight] = useState(false);
  function clickAvatar() {
    setRight(true);
  }

  function toggleDrawer(side, open) {
    setRight(open);
  }

  return (
    <Query query={GET_ME}>
      {({ loading, data }) => {
        if (loading) return null;
        const user = data.me;
        return (
          <TabNavigatorContainer>
            <FootWrapper>
              <TabNavigatorBlock>
                <a href="/">
                  {location == "home" ? (
                    <FontAwesomeIcon icon={fasHome} size="lg" />
                  ) : (
                    <FontAwesomeIcon icon={faHome} size="lg" />
                  )}
                  <div>Explore</div>
                </a>
              </TabNavigatorBlock>

              <TabNavigatorBlock>
                <Link to="/library">
                  {location == "library" ? (
                    <FontAwesomeIcon icon={fasBookmark} size="lg" />
                  ) : (
                    <FontAwesomeIcon icon={faBookmark} size="lg" />
                  )}
                  <div>Library</div>
                </Link>
              </TabNavigatorBlock>
              <TabNavigatorBlock onClick={clickAvatar}>
                <div className="achor-sim">
                  <FontAwesomeIcon icon={faUser} size="lg" />
                  <div>Account</div>
                </div>
              </TabNavigatorBlock>
            </FootWrapper>

            <Drawer
              anchor="right"
              open={right}
              onClose={() => toggleDrawer("right", false)}
            >
              <div
                tabIndex={0}
                role="button"
                onClick={() => toggleDrawer("right", false)}
                onKeyDown={() => toggleDrawer("right", false)}
              >
                <div className={classes.list}>
                  <List>
                    <a
                      href={`${url}/${user.username}`}
                      className="menu-anchor"
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faUserCircle} />
                        </ListItemIcon>
                        <ListItemText disableTypography>
                          <span className="codehealth-font-family sm">
                            {user.username}
                            <div className={classes.smText}>View Profile</div>
                          </span>
                        </ListItemText>
                      </ListItem>
                    </a>
                  </List>

                  <Divider />

                  <List>
                    <a
                      href={`${url}/account/settings/`}
                      className="menu-anchor"
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faCog} />
                        </ListItemIcon>
                        <ListItemText disableTypography>
                          <span className="codehealth-font-family sm">
                            Account Settings
                          </span>
                        </ListItemText>
                      </ListItem>
                    </a>
                  </List>

                  <Divider />

                  <List>
                    <a href={`${url}/cases/new-case`} className="menu-anchor">
                      <ListItem button>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faPlus} />
                        </ListItemIcon>
                        <ListItemText disableTypography>
                          <span className="codehealth-font-family sm">
                            Create a Case
                          </span>
                        </ListItemText>
                      </ListItem>
                    </a>

                    <a
                      href={`${url}/dashboard/?v=allContent&p=all`}
                      className="menu-anchor"
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faFolder} />
                        </ListItemIcon>
                        <ListItemText disableTypography>
                          <span className="codehealth-font-family sm">
                            Manage Your Content
                          </span>
                        </ListItemText>
                      </ListItem>
                    </a>

                    <a href={`${url}/dashboard`} className="menu-anchor">
                      <ListItem button>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faChartLine} />
                        </ListItemIcon>
                        <ListItemText disableTypography>
                          <span className="codehealth-font-family sm">
                            Your Stats
                          </span>
                        </ListItemText>
                      </ListItem>
                    </a>
                  </List>

                  <Divider />

                  <List>
                    <a
                      href={`${url}/account/subscribe/`}
                      className="menu-anchor"
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faAward} />
                        </ListItemIcon>
                        <ListItemText disableTypography>
                          <span className="codehealth-font-family sm">
                            CodeHealth Membership
                          </span>
                        </ListItemText>
                      </ListItem>
                    </a>
                  </List>

                  <Divider />

                  <List>
                    <a href={`${url}/contact`} className="menu-anchor">
                      <ListItem button>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faQuestionSquare} />
                        </ListItemIcon>
                        <ListItemText disableTypography>
                          <span className="codehealth-font-family sm">
                            Contact Us
                          </span>
                        </ListItemText>
                      </ListItem>
                    </a>

                    <a href={`${url}/logout`} className="menu-anchor">
                      <ListItem button>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faSignOut} />
                        </ListItemIcon>
                        <ListItemText disableTypography>
                          <span className="codehealth-font-family sm">
                            Sign Out
                          </span>
                        </ListItemText>
                      </ListItem>
                    </a>
                  </List>
                </div>
              </div>
            </Drawer>
          </TabNavigatorContainer>
        );
      }}
    </Query>
  );
}

export default withStyles(styles)(TabNavigator);

const TabNavigatorContainer = styled.div`
  display: none;
  z-index: 20;
  padding: 18px 0 34px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  border-top: 1px solid #e7e7e7;
  @media (max-width: 992px) {
    padding: 5px 0 10px;
  }
  @media (max-width: 576px) {
    display: block;
  }
`;
const TabNavigatorBlock = styled.div`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 0 25px;
  span {
    font-family: futura-pt, sans-serif;
    margin-left: 8px;
  }

  @media (max-width: 992px) {
    padding: 0;
    min-width: 20%;
    flex: 1 1 auto;
    position: relative;
    span {
      display: block;
      padding-top: 5px;
      margin-left: 0 !important;
      font-size: 8px;
      position: absolute;
      width: 100%;
      text-align: center;
    }
    a,
    .achor-sim {
      display: block;
      padding-top: 5px;
      margin-left: 0 !important;
      width: 100%;
      text-align: center;
      text-decoration: none;
      color: black;
      div {
        font-size: 8px;
      }
    }
  }
`;
const FootWrapper = styled.div`
  max-width: 1100px;
  margin: auto;
  @media (max-width: 992px) {
    padding: 5px 0 20px;
    display: flex;
    flex-wrap: wrap;
  }
`;
const PaddedSpan = styled.span`
  margin: 0 8px;
`;
