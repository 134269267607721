export const development = false;

export let url;
export let grandrounds;

if (development === true) {
  url = "http://127.0.0.1:8000";
  grandrounds = 64;
} else {
  url = "https://codehealth.io";
  grandrounds = 124;
}
