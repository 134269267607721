import React, { Fragment } from "react";

// Components
import Header from "../Components/Navigation/Header";
import TabNavigator from "../Components/Navigation/TabNavigator";
import CaseCard from "../CaseCard/CaseCard";

// Styles
import styled from "styled-components";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const CASE_FRAGMENT = gql`
  fragment CaseData on CaseType {
    id
    hashid
    caseCategory
    chiefComplaint
    clinicalScenario
    caseQuestion
    explanation
    caseTags
    casePhoto
    casePhotoThumb
    attempts
    correct
    incorrect
    layout
    caseType
    totalRating
    ratingAvg
    stemSet {
      id
      stem
    }
    questionSet {
      id
      question
    }
  }
`;
const USER_PROFILE = gql`
  query CURRENT_USER {
    user(username: "medzcool") {
      id
      profile {
        id
        bio
      }
      caseSet {
        ...CaseData
      }
    }
  }
  ${CASE_FRAGMENT}
`;

const USER_CASES = gql`
  query USER_CASES($userId: Int!) {
    cases(category: "userprofile", userId: $userId, count: 6) {
      ...CaseData
    }
  }
`;

export default function UserProfile(props) {
  return (
    <Query
      query={USER_PROFILE}
      variables={{
        username: props.match.params.username
      }}
    >
      {({ loading, data }) => {
        if (loading) return null;
        const { user } = data;
        const cases = user.caseSet;
        console.log(cases);
        return (
          <Fragment>
            <Header />
            <ProfileContainer>
              <ProfileInfo>
                <FontAwesomeIcon icon={faUserCircle} />
              </ProfileInfo>
              <ProfileCases>
                {cases.map(caseObject => {
                  return (
                    <CaseCard
                      caseObject={caseObject}
                      history={props.history}
                      key={caseObject.id}
                    />
                  );
                })}
              </ProfileCases>
            </ProfileContainer>
            <TabNavigator
              user={user}
              location={"profile"}
            />
          </Fragment>
        );
      }}
    </Query>
  );
}
const ProfileContainer = styled.div`
  max-width: 1300px;
  padding: 50px 0;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 75px;
`;
const ProfileInfo = styled.div`
  padding: 5px;
`;
const ProfileCases = styled.div`
  padding: 5px;
`;
