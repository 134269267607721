import React, { Fragment, useState, useEffect } from "react";

// Dependencies
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";

// Components
import CodeHealthEditorReadOnly from "./CodeHealthEditorReadOnly";

// Elements
import { CaseBlock, StyledLabel } from "../elements";

// Styles
import styled from "styled-components";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

function CaseStem(props) {
  const [hiddenStem, setHiddenStem] = useState(true);
  function stemToggle(stemState) {
    setHiddenStem(stemState);
  }

  return (
    <Fragment>
      {props.caseType == "clinical scenario" ? (
        <CaseBlock>
          <ModifiedStyledLabel>
            <span>Clinical Scenario</span>
          </ModifiedStyledLabel>
          {props.caseLayout !== "v3.0" ? (
            <Fragment>
              <ModifiedStyledLabel>
                <StemShow
                  answered={props.answered}
                  icon={faChevronDown}
                  hiddenStem={hiddenStem}
                  onClick={() => stemToggle(false)}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </StemShow>
                <StemHide
                  answered={props.answered}
                  hiddenStem={hiddenStem}
                  onClick={() => stemToggle(true)}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </StemHide>
              </ModifiedStyledLabel>
              <StemWrapper answered={props.answered} hiddenStem={hiddenStem}>
                <div
                  className="old-case-text"
                  dangerouslySetInnerHTML={props.oldStem}
                />
              </StemWrapper>
            </Fragment>
          ) : (
            <Fragment>
              <ModifiedStyledLabel>
                <StemShow
                  answered={props.answered}
                  icon={faChevronDown}
                  hiddenStem={hiddenStem}
                  onClick={() => stemToggle(false)}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </StemShow>
                <StemHide
                  answered={props.answered}
                  hiddenStem={hiddenStem}
                  onClick={() => stemToggle(true)}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </StemHide>
              </ModifiedStyledLabel>

              <StemWrapper answered={props.answered} hiddenStem={hiddenStem}>
                <CodeHealthEditorReadOnly
                  propsEditorState={props.stem}
                  onChange={true}
                />
              </StemWrapper>
            </Fragment>
          )}
        </CaseBlock>
      ) : (
        <CaseBlock>
          <ModifiedStyledLabel>
            <span>Question</span>
          </ModifiedStyledLabel>
          {props.caseLayout !== "v3.0" ? (
            // OLD QUESTION
            <Fragment>
              <ModifiedStyledLabel>
                <StemShow
                  answered={props.answered}
                  icon={faChevronDown}
                  hiddenStem={hiddenStem}
                  onClick={() => stemToggle(false)}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </StemShow>
                <StemHide
                  answered={props.answered}
                  hiddenStem={hiddenStem}
                  onClick={() => stemToggle(true)}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </StemHide>
              </ModifiedStyledLabel>
              <StemWrapper answered={props.answered} hiddenStem={hiddenStem}>
                <div
                  className="old-case-text question"
                  dangerouslySetInnerHTML={props.oldQuestion}
                />
              </StemWrapper>
            </Fragment>
          ) : (
            <Fragment>
              <ModifiedStyledLabel>
                <span>Question</span>
                <StemShow
                  answered={props.answered}
                  icon={faChevronDown}
                  hiddenStem={hiddenStem}
                  onClick={() => stemToggle(false)}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </StemShow>
                <StemHide
                  answered={props.answered}
                  hiddenStem={hiddenStem}
                  onClick={() => stemToggle(true)}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </StemHide>
              </ModifiedStyledLabel>
              <StemWrapper answered={props.answered} hiddenStem={hiddenStem}>
                <CodeHealthEditorReadOnly propsEditorState={props.question} />
              </StemWrapper>
            </Fragment>
          )}
        </CaseBlock>
      )}
    </Fragment>
  );
}
const ModifiedStyledLabel = styled(StyledLabel)`
  margin-bottom: 10px;
`;
const StemWrapper = styled.div`
  ${props => props.answered && props.hiddenStem && "display: none"}
  ${props =>
    props.answered && !props.hiddenStem && "display: block"}
  img,iframe {
    width: 100%;
  }
`;
const StemShow = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px 35px 25px 25px;
  margin-top: 5px;
  cursor: pointer;
  ${props => props.answered && props.hiddenStem && `display: block;`}
`;
const StemHide = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px 35px 25px 25px;
  margin-top: 5px;
  cursor: pointer;
  ${props => props.answered && !props.hiddenStem && `display: block;`}
`;

export default CaseStem;
