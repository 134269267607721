export const grayscale = {
    darkDark: '#333333',
    lightDark: '#999999',
    gray: '#D8D8D8',
    lightGray: '#F2F2F2',
    offWhite: '#FAFAFA'
}

export const purple = {
  darkDark: "#16111C",
  darkLight: "#3F2064",
  bright: "#5143BD",
  dull: "#565064",
  lightDark: "#C9C1E0",
  lightWhite: "#FAF8FE"
};

export const green = {
  dark: "#008C82",
  bright: "#5ED1A5"
};

export const red = {
  dark: "#D23D56",
  bright: "#F0445D"
};

export const yellow = {
  dark: "#E67E22",
  light: "#ffc820"
};
