import React, { Fragment, useEffect, lazy, Suspense } from "react";

// Components
import CaseStars from "./CaseStars";
import CaseStats from "./CaseStats";
import CaseCardStandard from "./CaseCardStandard";
import CaseCardExpanded from "./CaseCardExpanded";
import CaseCardList from "./CaseCardList";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../utilities";
import "../fonts/medzcool-glyphicons.css";



export default function CaseCard(props) {
  const { caseObject, mobile, desktop, responsive, history } = props;
  const { id, hashid, layout, caseType } = caseObject;
  function handleCaseClick(caseHash) {
    props.history.push(`/case/${caseHash}`);
  }

  function createMarkup(html) {
    let htmlText = html;
    const charCount = html.length;
    if (charCount > 250) {
      htmlText = html.slice(0, charCount - 250) + "...";
    }

    return { __html: htmlText };
  }

  let mobileDisplay;
  if (mobile === "expanded") {
    mobileDisplay = (
      <CaseCardExpanded caseObject={caseObject} history={history} />
    );
  } else if (mobile === "list") {
    mobileDisplay = <CaseCardList caseObject={caseObject} history={history} />;
  } else {
    mobileDisplay = null;
  }
  let desktopDisplay;
  if (desktop === "standard") {
    desktopDisplay = (
      <CaseCardStandard caseObject={caseObject} history={history} />
    );
  } else if (desktop === "list") {
    desktopDisplay = <CaseCardList caseObject={caseObject} history={history} />;
} else {
    desktopDisplay = null
}


  return (
    <Fragment>
      {mobileDisplay}
      {desktopDisplay}
    </Fragment>
  );
}
const CaseCardContainer = styled.div`
  display: inline-block;
`;
const CaseType = styled.div`
  font-family: futura-pt, sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  margin-top: 5px;
  color: ${grayscale.lightDark};
`;
const CaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
