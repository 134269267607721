import React, { Fragment } from "react";
import { url } from "../settings";

// Components
import Header from "../Components/Navigation/Header";
import CaseListFlex from "./CaseListFlex";
import TabNavigator from "../Components/Navigation/TabNavigator";

// Styles
import styled from "styled-components";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      id
      username
    }
  }
`;

export default function Library(props) {
  return (
    <Query query={CURRENT_USER}>
      {({ loading, data }) => {
        if (loading) return null;
        const { me } = data;
        const user = me;
        return (
          <Fragment>
            <Header label={"Library"} />
            <LibraryContainer>
              <CaseListFlex
                headerTitle={"Favorites"}
                category={"favorites"}
                description={"Revisit some of the cases you've loved."}
                user={user}
                count={8}
                history={props.history}
              />
              <Line />
              <CaseListFlex
                headerTitle={"History"}
                category={"history"}
                description={"Learn from cases you attempted in the past"}
                user={user}
                count={8}
                history={props.history}
              />
            </LibraryContainer>
            <TabNavigator user={props.user} location={"library"}/>
          </Fragment>
        );
      }}
    </Query>
  );
}

const LibraryContainer = styled.div`
  max-width: 1300px;
  padding: 50px 0;
  margin-right: auto;
  margin-left: auto;
  margin-bottom:75px;
`;
const Line = styled.hr`
  border: 1px solid #f2f2f2;
  margin: 70px 15px 30px 15px;
`;
