import React, { Fragment } from "react";
import { url } from "../settings";

// Components
import CaseThumbnail from "../CaseCard/CaseThumbnail";
import CaseStars from "../CaseCard/CaseStars";
import CaseStats from "../CaseCard/CaseStats";
import CaseCard from "../CaseCard/CaseCard";
import PureEditorReadOnly from "../PureEditorReadOnly";
import CaseCardHorizontalScroll from "../CaseCard/CaseCardHorizontalScroll";

// Styles
import styled from "styled-components";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

// Apollo, GraphQL
import { Query, Mutation, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";

const CASE_FRAGMENT = gql`
  fragment CaseData on CaseType {
    id
    hashid
    caseCategory
    chiefComplaint
    clinicalScenario
    caseQuestion
    explanation
    caseTags
    casePhoto
    casePhotoThumb
    attempts
    correct
    incorrect
    layout
    caseType
    totalRating
    ratingAvg
    stemSet {
      id
      stem
    }
    questionSet {
      id
      question
    }
  }
`;

const CASES = gql`
  query CASES($userId: Int!, $category: String!, $count: Int!) {
    cases(category: $category, userId: $userId, count: $count) {
      ...CaseData
    }
  }
  ${CASE_FRAGMENT}
`;

export default function CaseListFlex(props) {
  function createMarkup(html) {
    return { __html: html };
  }

  function handleCaseClick(caseHash) {
    props.history.push(`/case/${caseHash}`);
  }
  return (
    <GrandRoundsContainer>
      <GrandRoundsHeading>
        <h2>{props.headerTitle}</h2>
        {props.description && (
          <p className="description">{props.description}</p>
        )}
      </GrandRoundsHeading>

      <Query
        query={CASES}
        variables={{
          userId: props.user.id,
          category: props.category,
          count: props.count
        }}
      >
        {({ loading, data }) => {
          if (loading) return null;
          const { cases } = data;
          console.log(cases);

          return (
            <Fragment>
              <CaseCardHorizontalScroll cases={cases} history={props.history} />
            </Fragment>
          );
        }}
      </Query>

      <SeeMore>
        <a href={`${url}/${props.category}`}>
          <span>See All</span>
        </a>
        <FontAwesomeIconStyled icon={faChevronRight} />
      </SeeMore>
    </GrandRoundsContainer>
  );
}
const CaseCardContainer = styled.div`
  display: inline-block;
  margin: 0 10px;
  margin-bottom: 15px;
  width: 22.7%;
  vertical-align: top;
  white-space: normal;
  cursor: pointer;
  img {
    object-fit: cover;
    height: 170px;
    width: 100%;
  }
  .old-case-text {
    p {
      margin: 0;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .case-title {
    font-weight: 700;
    font-size: 15px;
    font-family: futura-pt, sans-serif;
  }
`;
const CaseType = styled.div`
  font-family: futura-pt, sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  margin-top: 5px;
  color: ${props => (props.type == "question" ? "#CF42B0" : "#4273CF")};
`;
const GrandRoundsContainer = styled.div`
  padding: 30px 0;
  @media (max-width: 576px) {
    padding: 0;
  }
`;
const GrandRoundsWrapper = styled.div`
  max-width: 1300px;
  margin: auto;
`;
const GrandRoundsHeading = styled.div`
  font-family: futura-pt, sans-serif;
  padding: 15px 50px;
  max-width: 1300px;
  margin: auto;
  h2 {
    font-size: 32px;
    margin-bottom: 5px;
  }
  .description {
    margin-top: 0;
    font-size: 16px;
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 26px;
      margin-bottom: 3px;
    }
  }
  @media (max-width: 576px) {
    padding: 15px;
  }
`;
const GrandRoundsBlock = styled.div`
  max-width: none;
  padding: 0 50px;
  font-family: futura-pt, sans-serif;
  max-width: 1300px
  margin: auto;
  @media (max-width: 1300px) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 576px) {
      padding: 15px 10px;
  }
  p {
    white-space: normal;
  }
  ${CaseCardContainer} {
    width: 22.7%;
    @media (max-width: 1200px) {
      width: 29%;
    }
    @media (max-width: 992px) {
      width: 40%;
    }
    @media (max-width:768px) {
        width: 46%
      }
    @media (max-width:660px) {
        width: 60%
    }
    @media (max-width: 576px) {
        width: 75%
    }
    @media (max-width: 425px) {
        width: 88%
    }
  }
`;
const SeeMore = styled.div`
    max-width: none;
    padding: 0 50px;
    font-family: futura-pt, sans-serif;
    max-width: 1300px
    margin: auto;
  a {
    margin-right: 5px;
    text-decoration: none;
    color:black;
  }
  @media (max-width: 576px) {
      padding: 0px 15px;
  }
  p {
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  vertical-align: middle;
`;
