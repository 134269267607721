import React, { Fragment, useState } from "react";
import { url } from "../settings";

// Dependencies
import axios from "axios";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faStarHalf,
  faHeart as fasHeart
} from "@fortawesome/pro-solid-svg-icons";
import { faHeart } from "@fortawesome/pro-regular-svg-icons";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../utilities";

// Apollo, GraphQL
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";

const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      id
      username
    }
  }
`;

const GET_FAVORITE_CASES = gql`
  query GET_FAVORITE_CASES($userId: Int!) {
    favoriteCases(userId: $userId) {
      id
      hashid
      chiefComplaint
    }
  }
`;

const HANDLE_FAVORITE = gql`
  mutation HANDLE_FAVORITE($userId: Int!, $caseId: Int!) {
    handleFavoriteCase(userId: $userId, caseId: $caseId) {
      favoriteCases {
        id
        hashid
      }
    }
  }
`;

function InteractFavorite(props) {
  const caseId = props.caseObject.id;
  const [isFavorited, setIsFavorited] = useState(false);

  function handleFavoriteClick() {
    setIsFavorited(!isFavorited);
  }

  // isInitialized allows toggle of isFavorited
  // without this, isCaseFavorited() will be called on every render
  // when favorite is clicked, it will toggle isFavorite
  // but because is the component re-renders, isCaseFavorited()
  // this will cause a "re-toggle", thus changing isFavorite to opposite value
  // e.g. (isFavorite = False) => (onClick) = > (isFavorite = True) => (re-render) => (isFavorite = False)
  const [isInitialized, setIsInitialized] = useState(false);
  // Determine if this case favorited on component mounting
  function isCaseFavorited(favoriteCases) {
    if (!isInitialized) {
      const favCaseMatch = favoriteCases.find(faveCase => {
        return faveCase.id == caseId;
      });
      if (favCaseMatch) {
        setIsFavorited(true);
      } else {
        setIsFavorited(false);
      }
      setIsInitialized(true);
    }
  }

  return (
    <Query query={CURRENT_USER}>
      {({ loading, data }) => {
        if (loading) return null;
        const { me } = data;
        const userId = me.id;
        return (
          <Query query={GET_FAVORITE_CASES} variables={{ userId: userId }}>
            {({ loading, error, data }) => {
              if (loading) return "Loading...";
              if (error) return `Error! ${error.message}`;
              const { favoriteCases } = data;
              isCaseFavorited(favoriteCases);
              return (
                <Mutation mutation={HANDLE_FAVORITE}>
                  {handleFavoriteCase => (
                    <FooterBlock
                      onClick={() => {
                        handleFavoriteClick();
                        handleFavoriteCase({ variables: { userId, caseId } });
                      }}
                      isFavorited={isFavorited}
                    >
                      {isFavorited ? (
                        <FontAwesomeIcon icon={fasHeart} size="lg" />
                      ) : (
                        <FontAwesomeIcon icon={faHeart} size="lg" />
                      )}
                      <span>FAVORITE</span>
                    </FooterBlock>
                  )}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
}
export default InteractFavorite;

const FooterBlock = styled.div`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 0 25px;
  span {
    font-family: futura-pt, sans-serif;
    margin-left: 8px;
  }
  ${props => props.isFavorited && `color:${red.bright}`}
  @media (max-width: 992px) {
    padding: 0;
    min-width: 20%;
    flex: 1 1 auto;
    position: relative;
    span {
      display: block;
      padding-top: 5px;
      margin-left: 0 !important;
      font-size: 8px;
      position: absolute;
      width: 100%;
      text-align: center;
    }
  }
`;
const Loading = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 14px;
  text-align: center;
  color: gray;
`;
