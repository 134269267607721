import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  lazy,
  Suspense
} from "react";
import { url } from "./settings";
import logo from "./logo.svg";
import "./App.css";

// Components
import Homepage from "./Homepage";
import HomepageNonAuth from "./HomepageNonAuth";
import Collection from "./Collections/Collection";
import Library from "./Library/Library";
import UserProfile from "./UserProfile/UserProfile";
import CaseDetails from "./CaseDetails/CaseDetails";
import Spinner from "./Spinner";
import NoMatch from "./NoMatch";

// Dependencies
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import axios from "axios";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_ME = gql`
  query GET_ME {
    me {
      id
      username
      profile {
          subscriptionState
      }
    }
  }
`;

function App(props) {
  const [responseData, setResponseData] = useState({});
  const [caseTarget, setCaseTarget] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  function updateFavoriteCases(favoriteCases) {
    setResponseData(prevState => ({
      ...prevState,
      favoriteCases: favoriteCases
    }));
  }

  function updateCases(updatedCase) {
    setResponseData(prevState => ({
      ...prevState,
      cases: updatedCase
    }));
  }

  return (
    <Router>
      <Query query={GET_ME}>
        {({ loading, data }) => {
          if (loading) return <Spinner />;
          if (!data) {
            return (
              <Switch>
                <Route
                  exact
                  path="/case/:caseHash/"
                  render={props => (
                    <CaseDetails
                      {...props}
                      user={user}
                      responseData={responseData}
                      updateState={{
                        updateFavoriteCases: updateFavoriteCases,
                        updateCases: updateCases
                      }}
                    />
                  )}
                />
                <Route
                  exact
                  path="/"
                  render={props => (
                    <HomepageNonAuth
                      {...props}
                      responseData={responseData}
                    />
                  )}
                />
                <Route component={NoMatch} />
              </Switch>
            );
          }

          const user = data.me;
          return (
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <Homepage
                    {...props}
                    responseData={responseData}
                    user={user}
                  />
                )}
              />
              <Route
                exact
                path="/case/:caseHash/"
                render={props => (
                  <CaseDetails
                    {...props}
                    user={user}
                    responseData={responseData}
                    updateState={{
                      updateFavoriteCases: updateFavoriteCases,
                      updateCases: updateCases
                    }}
                  />
                )}
              />
              <Route
                path="/collection/"
                render={props => (
                  <Collection
                    {...props}
                    user={user}
                  />
                )}
              />
              <Route
                exact
                path="/library/"
                render={props => <Library {...props} user={user} />}
              />
              <Route
                path="/logout"
                component={() => {
                  window.location = "https://codehealth.io/logout";
                  return null;
                }}
              />
              <Route
                exact
                path="/:username/"
                render={props => <UserProfile {...props} />}
              />
              <Route component={NoMatch} />
            </Switch>
          );
        }}
      </Query>
    </Router>
  );
}

export default App;
