import React, { Fragment } from "react";
import { url } from "./settings";

// Components
import CaseThumbnail from "./CaseCard/CaseThumbnail";
import CaseStars from "./CaseCard/CaseStars";
import CaseStats from "./CaseCard/CaseStats";
import PureEditorReadOnly from "./PureEditorReadOnly";
// Dependencies
import { Link } from "react-router-dom";

// Styles
import styled from "styled-components";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

export default function GrandRounds(props) {
  const { handleCaseClick } = props;
  function createMarkup(html) {
      let htmlText = html;
      const charCount = html.length;
      if (charCount > 250) {
        htmlText = html.slice(0, charCount - 250) + "...";
      }

      return { __html: htmlText };
  }
  return (
    <GrandRoundsContainer>
      <GrandRoundsHeading>
        <h2>Grand Rounds</h2>
        <p className="description">
          Traditionally, a style of medical education where doctors, residents
          and students discussed medical pathology and treatments. CodeHealth
          Grand Rounds features some of the most interesting cases on
          CodeHealth. Updated Monthly.
        </p>
      </GrandRoundsHeading>
      <GrandRoundsBlock>
        {props.cases.map(caseObject => {
          if (caseObject.hashid) {
            return (
              <CaseCardContainer
                key={caseObject.hashid}
                value={caseObject.hashid}
                onClick={() => handleCaseClick(caseObject.hashid)}
              >
                <div className="desktop">
                  <CaseThumbnail
                    thumbnail={caseObject.casePhoto || caseObject.casePhotoThumb}
                    category={caseObject.caseCategory}
                    case={caseObject}
                  />

                  <CaseType type={caseObject.caseType}>
                    {caseObject.caseType}
                  </CaseType>

                  <div className="case-title">{caseObject.chiefComplaint}</div>

                  <CaseStars caseRating={caseObject.ratingAvg} />

                  <CaseStats
                    color={"white"}
                    correct={caseObject.correct}
                    incorrect={caseObject.incorrect}
                  />
                </div>
              </CaseCardContainer>
            );
          } else {
            if (caseObject.caseType == "question") {
              return (
                <CaseCardContainer
                  key={caseObject.id}
                  value={caseObject.id}
                  onClick={() => handleCaseClick(caseObject.id)}
                >
                  <div className="desktop">
                    <CaseThumbnail
                      thumbnail={caseObject.casePhoto || caseObject.casePhotoThumb}
                      category={caseObject.caseCategory}
                      case={caseObject}
                    />

                    <CaseType>{caseObject.caseType}</CaseType>

                    <div
                      className="old-case-text case-title"
                      dangerouslySetInnerHTML={createMarkup(
                        caseObject.clinicalScenario
                      )}
                    />

                    <CaseStars caseRating={caseObject.ratingAvg} />
                    <CaseStats
                      color={"white"}
                      correct={caseObject.correct}
                      incorrect={caseObject.incorrect}
                    />
                  </div>
                </CaseCardContainer>
              );
            } else {
              return (
                <CaseCardContainer
                  key={caseObject.id}
                  value={caseObject.id}
                  onClick={() => handleCaseClick(caseObject.id)}
                >
                  <div className="desktop">
                    <CaseThumbnail
                      thumbnail={caseObject.casePhoto || caseObject.casePhotoThumb}
                      category={caseObject.caseCategory}
                      case={caseObject}
                    />
                    <CaseType type={caseObject.caseType}>
                      {caseObject.caseType}
                    </CaseType>
                    <div className="case-title">
                      {caseObject.chiefComplaint}
                    </div>
                    <CaseStars caseRating={caseObject.ratingAvg} />
                    <CaseStats
                      color={"white"}
                      correct={caseObject.correct}
                      incorrect={caseObject.incorrect}
                    />
                  </div>
                </CaseCardContainer>
              );
            }
          }
        })}
      </GrandRoundsBlock>
      <SeeMore>
        <Link to={`/collection/grandrounds`}>
          <span>See More Grandround Cases</span>
        </Link>
        <FontAwesomeIconStyled icon={faChevronRight} />
      </SeeMore>
    </GrandRoundsContainer>
  );
}
const CaseCardContainer = styled.div`
  display: inline-block;
  margin: 0 10px;
  margin-bottom: 40px;
  width: 22.7%;
  vertical-align: top;
  white-space: normal;
  cursor: pointer;
  img {
    object-fit: cover;
    height: 170px;
    width: 100%;
  }
  .old-case-text {
    p {
      margin: 0;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .case-title {
    font-weight: 700;
    font-size: 15px;
    font-family: futura-pt, sans-serif;
  }
  @media (max-width: 576px) {
      img {
        height: 200px;
      }
  }
`;
const CaseType = styled.div`
  font-family: futura-pt, sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  margin-top: 5px;
  color: ${props => (props.type == "question" ? "#CF42B0" : "#4273CF")};
`;
const GrandRoundsContainer = styled.div`
  background: #d23d56;
  background-image: linear-gradient(45deg, #f0445d 50%, #ef8c64);
  padding: 30px 0;
  @media (max-width: 576px) {
    padding: 0;
  }
`;
const GrandRoundsWrapper = styled.div`
  max-width: 1300px;
  margin: auto;
`;
const GrandRoundsHeading = styled.div`
  font-family: futura-pt, sans-serif;
  padding: 0px 50px 15px;
  color: white;
  max-width: 1300px;
  margin: auto;
  @media (max-width: 576px) {
    padding: 15px;
  }
  h2 {
    font-size: 32px;
    margin-top:5px;
    margin-bottom: 5px;
  }
  .description {
    margin-top: 0;
    font-size: 16px;
  }
`;
const GrandRoundsBlock = styled.div`
  max-width: none;
  padding: 0 50px;
  font-family: futura-pt, sans-serif;
  max-width: 1300px
  margin: auto;
  @media (max-width: 1300px) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 576px) {
      padding: 0 10px;
  }
  p {
    white-space: normal;
  }
  ${CaseCardContainer} {
    width: 22.7%;
    @media (max-width: 1200px) {
      width: 29%;
    }
    @media (max-width: 992px) {
      width: 40%;
    }
    @media (max-width:768px) {
        width: 46%
      }
    @media (max-width:660px) {
        width: 60%
    }
    @media (max-width: 576px) {
        width: 75%
    }
    @media (max-width: 425px) {
        width: 88%
    }
  }
  ${CaseType} {
    color: white;
  }
  .case-title {
    color:white;
  }
`;
const SeeMore = styled.div`
    max-width: none;
    padding: 0 50px;
    font-family: futura-pt, sans-serif;
    max-width: 1300px
    margin: auto;
  a {
    color: white;
    margin-right: 5px;
  }
  @media (max-width: 576px) {
      padding: 0 15px 30px 15px;
  }
  p {
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  vertical-align: middle;
  color: white;
`;
