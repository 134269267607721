import React, { Suspense, lazy } from "react";

// Components
import PureEditorReadOnly from "../PureEditorReadOnly";
import CaseThumbnail from "./CaseThumbnail";
import CaseStars from "./CaseStars";
import CaseStats from "./CaseStats";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../utilities";

export default function CaseCardStandard(props) {
  const { caseObject, history } = props;
  const { layout, hashid, id, caseType } = caseObject;

  function handleCaseClick(id) {
    history.push(`/case/${id}`);
  }

  function createMarkup(html) {
    let htmlText = html;
    const charCount = html.length;
    if (charCount > 250) {
      htmlText = html.slice(0, charCount - 250) + "...";
    }

    return { __html: htmlText };
  }

  return (
    <CaseCardStandardContainer onClick={() => handleCaseClick(hashid || id)}>
      <Suspense fallback={<div />}>
        <CaseThumbnail
          thumbnail={caseObject.casePhotoThumb || caseObject.casePhoto}
          category={caseObject.caseCategory}
          case={caseObject}
        />
      </Suspense>

      <CaseType>{caseObject.caseType}</CaseType>

      {caseType === "question" ? (
        <div className="case-title">{caseObject.chiefComplaint}</div>
      ) : (
        <div className="case-title">{caseObject.chiefComplaint}</div>
      )}

      <CaseStars caseRating={caseObject.ratingAvg} />

      <CaseStats
        correct={caseObject.correct}
        incorrect={caseObject.incorrect}
      />
    </CaseCardStandardContainer>
  );
}
const CaseCardStandardContainer = styled.div`
  display: inline-block;
  margin: 0 10px;
  margin-bottom: 50px;
  vertical-align: top;
  white-space: normal;
  cursor: pointer;
  img {
    object-fit: cover;
    height: 170px;
    width: 100%;
  }
  .old-case-text {
    p {
      margin: 0;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .case-title {
    font-weight: 700;
    font-size: 15px;
    font-family: futura-pt, sans-serif;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;
const CaseType = styled.div`
  font-family: futura-pt, sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  margin-top: 5px;
  color: ${grayscale.lightDark};
`;
