import React, { Component, Fragment, useState, useEffect } from "react";
import { url } from "../settings";

// Components

// Dependencies
import { Route, Link } from "react-router-dom";
import axios from "axios";

// Styles
import styled from "styled-components";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";

function CaseStars(props) {
  const [caseRating, setCaseRating] = useState(0);

  useEffect(() => {
    const rating = props.caseRating;
    if (rating <= 0.5) {
    }
  });

  return (
    <CaseStatsContainer color={props.color}>
      <Stat>
        <FontAwesomeIcon icon={faCheck} />
        <span>{props.correct}</span>
      </Stat>
      <Stat>
        <FontAwesomeIcon icon={faTimes} />
        <span>{props.incorrect}</span>
      </Stat>
    </CaseStatsContainer>
  );
}
const CaseStatsContainer = styled.div`
  color: gray;
  color: ${props => (props.color == "white" ? "#ffffff" : "#d3d3d3")};
  span {
    font-family: futura-pt, sans-serif;
  }
`;
const Stat = styled.span`
  font-size: 13px;
  margin-right: 12px;
  span {
    margin-left: 3px;
  }
`;

export default CaseStars;
