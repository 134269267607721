import React, { Fragment, useEffect, useState } from "react";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcaseMedical, faAward } from "@fortawesome/pro-solid-svg-icons";

// Styles
import styled from "styled-components";
import { yellow } from "../utilities";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_CASE = gql`
  query GET_CASE($hashid: String!) {
    case(hashid: $hashid) {
      id
      hashid
      isPremium
      casePhoto
      casePhotoThumb
    }
  }
`;

function CaseThumbnail(props) {
  const [thumbnail, setThumbnail] = useState();
  const [loading, setLoading] = useState(true);
  const photoStyle = props.photoStyle || null;

  const { isPremium } = props.case;

  useEffect(() => {
    if (props.thumbnail) {
      setThumbnail(<img src={props.thumbnail} />);
    } else {
      if (props.category) {
        const classIcon = `icon icon-${props.category}`;
        setThumbnail(
          <StyledIcon className={classIcon} photoStyle={photoStyle} />
        );
      } else {
        const classIcon = `icon icon-${props.category}`;
        setThumbnail(
          <StyledFontAwesomeIcon
            icon={faBriefcaseMedical}
            photoStyle={photoStyle}
          />
        );
      }
    }
    setLoading(false);
  }, []);

  return (
    <Fragment>
      <PhotoContainer photoStyle={photoStyle}>
        {isPremium && (
          <StyledFontAwesomeIcon icon={faAward} size="2x" premium={"true"} />
        )}

        {thumbnail}
      </PhotoContainer>
    </Fragment>
  );
}

export default CaseThumbnail;

const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #171e3a;
  height: 170px;
  position: relative;
  img {
    position: absolute;
  }
  ${props =>
    props.photoStyle == "list"
      ? `height: 130px`
      : props.photoStyle == "horizontalScroll"
      ? `height:200px`
      : `height:170px;`};
  @media (max-width: 768px) {
    ${props =>
      props.photoStyle == "list"
        ? `height: 115px`
        : props.photoStyle == "horizontalScroll"
        ? `height:200px`
        : `height:170px;`};
  }
  @media (max-width: 576px) {
    ${props =>
      props.photoStyle == "list"
        ? `height: 115px`
        : props.photoStyle == "horizontalScroll"
        ? `height:200px`
        : `height:200px;`};
  }
`;
const StyledIcon = styled.div`
  text-align: center;
  font-size: 80px;
  position: relative;
  top: 0;
  color: #d23d56;
  width: auto !important;
  @media (max-width: 768px) {
    ${props =>
      props.photoStyle == "list" ? `font-size: 50px` : `font-size: 80px`};
  }
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  text-align: center;
  font-size: 80px;
  position: relative;
  top: 0;
  color: #d23d56;
  width: auto !important;
  ${props =>
    props.premium == "true" &&
    `position:absolute; top:8px; right:8px; color:${
      yellow.dark
    }; z-index: 2; font-size: 30px;`};
`;
