import React, { Fragment } from "react";

// Components
import CaseCard from "./CaseCard";

// Styles
import styled from "styled-components";

export default function CaseContainer(props) {
  const { cases, desktopLayout, mobile, desktop, history } = props;
  return (
    <Fragment>
      {desktopLayout === "grid" ? (
        <CollectionGridContainer>
          {cases.map(caseObject => {
            return (
              <CaseCard
                desktop={desktop}
                caseObject={caseObject}
                history={history}
                key={caseObject.id}
              />
            );
          })}
        </CollectionGridContainer>
      ) : (
        <Fragment>
          {cases.map(caseObject => {
            return (
              <CaseCard
                mobile={mobile}
                desktop={desktop}
                caseObject={caseObject}
                history={history}
                key={caseObject.id}
              />
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
}
const CollectionGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 992px) {
    display:none;
  }
`;
