import React, { Component, Fragment } from "react";
import "./Header.css";
import DrawerMenuLeft from "./DrawerMenuLeft";
import DrawerMenuRight from "./DrawerMenuRight";
import { url } from "../../settings";

// Dependencies
import { Link } from "react-router-dom";

// Material UI
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHome, faBookmark } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDoubleLeft } from "@fortawesome/pro-solid-svg-icons";

// Styles
import styled from "styled-components";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_ME = gql`
  query GET_ME {
    me {
      id
      username
      profile {
        subscriptionState
      }
    }
  }
`;

const styles = {
  createContainer: {
    display: "inline-block",
    marginLeft: 35,
    fontSize: 12,
    cursor: "pointer",
    padding: "8px 18px",
    background: "#D23D56",
    borderRadius: 3
  },
  createText: {
    color: "white",
    textDecoration: "none"
  },
  betaTag: {
    fontSize: 8,
    color: "#D23D56",
    verticalAlign: "text-top",
    fontWeight: 600
  },
  "@media (max-width: 960px)": {
    createContainer: {
      display: "none"
    }
  }
};

class App extends Component {
  state = {
    user: {
      username: "",
      avatar: ""
      // subscriptionState: ""
    },
    isLoading: true
  };

  componentDidMount() {
    this.setState({
      isLoading: false
    });
  }

  render() {
    const { classes } = this.props;
    let upgradeButton;

    if (!this.state.user.subscriptionState) {
      upgradeButton = (
        <div className={classes.createContainer}>
          <a href="/account/subscribe/" className={classes.createText}>
            Upgrade
          </a>
        </div>
      );
    } else {
      upgradeButton = null;
    }

    if (this.state.isLoading) {
      return null;
    } else {
      return (
        <Fragment>
          <header className="react-header">
            <Query query={GET_ME}>
              {({ loading, data }) => {
                if (loading) return null;
                if (!data) {
                  return (
                    <div className="header-nav">
                      <div className="left-nav">
                        <StyledHomeIcon>
                          <Link to="/">
                            <FontAwesomeIcon
                              icon={faChevronDoubleLeft}
                              size="lg"
                            />
                          </Link>
                        </StyledHomeIcon>
                      </div>

                      {this.props.label && (
                        <HeaderLabel>{this.props.label}</HeaderLabel>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div className="header-nav">
                      <div className="left-nav">
                        <DrawerMenuLeft />
                      </div>

                      {this.props.label && (
                        <HeaderLabel>{this.props.label}</HeaderLabel>
                      )}

                      <div className="right-nav">
                        <DrawerMenuRight history={this.props.history} />
                      </div>
                    </div>
                  );
                }
              }}
            </Query>
          </header>
        </Fragment>
      );
    }
  }
}

export default withStyles(styles)(App);

const HeaderLabel = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
  padding: 15px 0;
`;
const StyledHomeIcon = styled.div`
  margin-left: 15px;
  margin-top: 7px;
`;
