import React, { Suspense, lazy } from "react";

// Components
import PureEditorReadOnly from "../PureEditorReadOnly";
import CaseStars from "./CaseStars";
import CaseStats from "./CaseStats";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../utilities";

const CaseThumbnail = lazy(() => import("./CaseThumbnail"));

export default function CaseCardExpanded(props) {
  const { caseObject, history } = props;
  const { layout, id, hashid } = caseObject;

  function handleCaseClick(id) {
    history.push(`/case/${id}`);
  }

  function createMarkup(html, limitChar) {
    let htmlText = html;
    const charCount = html.length;
    if (charCount > 250) {
      htmlText = html.slice(0, limitChar) + "...";
    }

    return { __html: htmlText };
  }

  if (layout == "v3.0") {
    return (
      <CaseCardExtendedContainer onClick={() => handleCaseClick(hashid || id)}>
        <div className="mobile-block">
          <CaseType type={caseObject.caseType}>{caseObject.caseType}</CaseType>
          <div className="case-title">{caseObject.chiefComplaint}</div>
          <CaseStars caseRating={caseObject.ratingAvg} />
        </div>

        <Suspense fallback={<div />}>
          <CaseThumbnail
            thumbnail={caseObject.casePhotoThumb || caseObject.casePhoto}
            category={caseObject.caseCategory}
            case={caseObject}
            photoStyle={"horizontalScroll"}
          />
        </Suspense>

        <div className="stats-block">
          <CaseStats
            correct={caseObject.correct}
            incorrect={caseObject.incorrect}
          />
        </div>

        <div className="old-info">
          {caseObject.stemSet[0] ? (
            <PureEditorReadOnly
              propsEditorState={caseObject.stemSet[0].stem}
              onChange={true}
            />
          ) : (
            <PureEditorReadOnly
              propsEditorState={caseObject.questionSet[0].question}
              onChange={true}
            />
          )}
        </div>
      </CaseCardExtendedContainer>
    );
  } else {
    return (
      <CaseCardExtendedContainer onClick={() => handleCaseClick(hashid || id)}>
        <div className="mobile-block">
          <CaseType type={caseObject.caseType}>{caseObject.caseType}</CaseType>

          {caseObject.caseType === "question" ? (
            <div
              className="old-case-text case-title"
              dangerouslySetInnerHTML={createMarkup(
                caseObject.clinicalScenario,
                50
              )}
            />
          ) : (
            <div className="case-title">{caseObject.chiefComplaint}</div>
          )}

          <CaseStars caseRating={caseObject.ratingAvg} />
        </div>

        <Suspense fallback={<div />}>
          <CaseThumbnail
            thumbnail={caseObject.casePhotoThumb || caseObject.casePhoto}
            category={caseObject.caseCategory}
            case={caseObject}
          />
        </Suspense>

        <div className="stats-block">
          <CaseStats
            correct={caseObject.correct}
            incorrect={caseObject.incorrect}
          />
        </div>

        {caseObject.caseType !== "question" && (
          <div
            className="old-info"
            dangerouslySetInnerHTML={createMarkup(
              caseObject.clinicalScenario,
              250
            )}
          />
        )}
      </CaseCardExtendedContainer>
    );
  }
}
const CaseCardExtendedContainer = styled.div`
  display: none;
  .old-case-text {
    p {
      margin: 0;
    }
  }
  .case-title {
    font-weight: 700;
    font-size: 15px;
    font-family: futura-pt, sans-serif;
  }
  .mobile-block {
    padding: 15px;
    .case-title {
      font-size: 19px;
    }
  }
  .old-info {
    padding: 0px 15px 15px 15px;
    font-family: adobe-text-pro, serif;
    font-size: 14px;
    line-height: 20px;
    p {
      margin: 0;
    }
  }
  .stats-block {
    padding: 5px 15px;
  }
  @media (max-width: 576px) {
    display: block;
    width: 100%;
    margin: 5px 0;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    padding: 0;
    background: white;
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }
`;
const CaseType = styled.div`
  font-family: futura-pt, sans-serif;
  text-transform: capitalize;
  font-size: 11px;
  margin-top: 5px;
  color: ${grayscale.lightDark};
`;
