import React, { Fragment } from "react";

// Components
import Spinner from "../Spinner";
import TabNavigator from "../Components/Navigation/TabNavigator";
import Header from "../Components/Navigation/Header";
import CaseContainer from "../CaseCard/CaseContainer";
import CollectionThumbnail from "./CollectionThumbnail";
import CollectionDetail from "./CollectionDetail";

// Dependencies
import { Switch, Route, Redirect } from "react-router-dom";

// Styles
import styled from "styled-components";
import { fontFamily, fontType, grayscale } from "../utilities";

export default function Collection(props) {
  return (
    <Switch>
      <Route
        exact
        path="/collection/:collectionId/"
        render={props => <CollectionDetail {...props} />}
      />
      <Route path="/" render={() => <Redirect to="/" />} />
    </Switch>
  );
}
const CollectionContainer = styled.div`
  max-width: 1300px;
  margin: auto;
  margin-top: 75px;
  margin-bottom: 75px;
  display: grid;
  grid-template-columns: 300px auto;
  grid-column-gap: 35px;
  @media (max-width: 1300px) {
    grid-column-gap: 20px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 200px auto;
  }
  @media (max-width: 625px) {
    grid-template-columns: 100%;
    grid-row-gap: 20px;
  }
  @media (max-width: 425px) {
    margin-top: 60px;
  }
`;
const CollectionInfo = styled.div`
  h1 {
    margin-top: 0;
    font-family: ${fontFamily.sansSerif};
    ${fontType.pageTitle}
  }
  .collection-label {
    margin-bottom: 0;
    font-family: ${fontFamily.sansSerif};
    color: ${grayscale.lightDark};
    ${fontType.small};
  }
  .description {
    font-family: ${fontFamily.sansSerif};
    ${fontType.small}
  }
  @media (max-width: 1300px) {
    margin-left: 15px;
  }
  @media (max-width: 625px) {
    margin: 0 15px;
  }
  @media (max-width: 625px) {
    margin: 0 10px;
    h1 {
      margin: 0;
    }
    .description {
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
`;
const CasesContainer = styled.div``;
