import React, { Fragment } from "react";
import { url } from "../settings";

// Dependencies
import axios from "axios";

// Material UI
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/pro-regular-svg-icons";

// Styles
import styled from "styled-components";
import { purple, green, red, grayscale } from "../utilities";

// Apollo, GraphQL
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";

const GET_CASESETS = gql`
  query GET_CASESETS($userId: Int!) {
    casesets(userId: $userId) {
      id
      setTitle
      caseSet {
        id
      }
    }
  }
`;

const HANDLE_CASESETS = gql`
  mutation HANDLE_CASESETS($userId: Int!, $caseId: Int!, $casesetId: Int) {
    handleCaseSets(userId: $userId, caseId: $caseId, casesetId: $casesetId) {
      casesets {
        id
        setTitle
        caseSet {
          id
        }
      }
    }
  }
`;

const CREATE_CASESET = gql`
  mutation CREATE_CASESET($userId: Int!, $casesetTitle: String!) {
    createCaseSet(userId: $userId, casesetTitle: $casesetTitle) {
      caseset {
        id
        setTitle
        caseSet {
          id
        }
      }
    }
  }
`;

class CaseSetDialog extends React.Component {
  state = {
    open: this.props.open,
    casesetTitle: "",
    creatingCaseSet: false,
    dialogTitle: this.props.dialogTitle,
    casesets: [],
    loading: true,
    userId: this.props.userId,
    caseId: this.props.caseId
  };

  caseInCaseset = caseset => {
    const includedCase = caseset.caseSet.find(caseObj => {
      return caseObj.id == this.props.caseId;
    });
    return includedCase;
  };

  handleClose = () => {
    this.props.closeDialog();
  };

  handleCaseSetTitle = e => {
    const title = e.target.value;
    const titleLength = e.target.value.length;
    if (titleLength <= 60) {
      this.setState({ casesetTitle: title });
    }
  };

  handleCreateCaseSet = (e, value, func) => {
    const casesetTitle = this.state.casesetTitle;
    if (value == "create") {
      if (this.state.casesetTitle.length < 1) {
        alert("Your case set has no title");
      } else {
        console.log("creeate caseset");
        this.setState({
          creatingCaseSet: false,
          casesetTitle: ""
        });
        return casesetTitle;
      }
    } else if (value == "delete all") {
      axios
        .post(`${url}/api/v0.1/handle-caseset/`, {
          userId: this.props.userId,
          action: "delete all"
        })
        .then(response => {
          const { successMsg, casesets } = response.data;
          console.log(successMsg, casesets);
          this.setState({ casesets: casesets, creatingCaseSet: false });
        });
    } else {
      this.setState({ creatingCaseSet: true, dialogTitle: "Create Case Set" });
    }
  };

  handleCancelCreate = () => {
    this.setState({ creatingCaseSet: false, dialogTitle: "Add To Case Set" });
  };

  render() {
    const userId = this.props.userId;
    const caseId = this.props.caseId;
    const { fullScreen } = this.props;
    return (
      <Query query={GET_CASESETS} variables={{ userId }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          const casesets = data.casesets;
          console.log(casesets);
          return (
            <Dialog
              fullScreen={fullScreen}
              open={this.props.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContainer>
                <StyledDialogTitle>{this.state.dialogTitle}</StyledDialogTitle>

                <DialogContent />

                <DialogContent>
                  {this.state.creatingCaseSet ? (
                    <TextInput>
                      <input
                        type="text"
                        onChange={this.handleCaseSetTitle}
                        value={this.state.casesetTitle}
                        placeholder="Case Set Title"
                      />
                    </TextInput>
                  ) : (
                    <Fragment>
                      {casesets.length < 1 ? (
                        <ErrorCaseSets>
                          You have not created any Case Sets
                        </ErrorCaseSets>
                      ) : (
                        casesets.map(caseset => (
                          <Mutation mutation={HANDLE_CASESETS} key={caseset.id}>
                            {handleCaseSets => (
                              <CaseSet
                                included={this.caseInCaseset(caseset)}
                                key={caseset.id}
                                value={caseset.id}
                                onClick={(e, value) => {
                                  handleCaseSets({
                                    variables: {
                                      caseId,
                                      userId,
                                      casesetId: caseset.id
                                    }
                                  });
                                }}
                              >
                                <FontAwesomeIcon icon={faClone} />
                                <span>{caseset.setTitle}</span>
                              </CaseSet>
                            )}
                          </Mutation>
                        ))
                      )}
                    </Fragment>
                  )}
                </DialogContent>

                <DialogActions>
                  {this.state.creatingCaseSet ? (
                    <Fragment>
                      <Mutation
                        mutation={CREATE_CASESET}
                        update={(
                          cache,
                          {
                            data: {
                              createCaseSet: { caseset }
                            }
                          }
                        ) => {
                          const { casesets } = cache.readQuery({
                            query: GET_CASESETS,
                            variables: { userId }
                          });
                          cache.writeQuery({
                            query: GET_CASESETS,
                            variables: {
                              userId
                            },
                            data: { casesets: casesets.concat([caseset]) }
                          });
                        }}
                      >
                        {createCasetSet => (
                          <Button
                            onClick={e => {
                              createCasetSet({
                                variables: {
                                  userId: this.state.userId,
                                  casesetTitle: this.handleCreateCaseSet(
                                    e,
                                    "create"
                                  )
                                }
                              });
                            }}
                            color="primary"
                            autoFocus
                          >
                            <StyledButton>Create Case Set</StyledButton>
                          </Button>
                        )}
                      </Mutation>
                      <Button
                        onClick={this.handleCancelCreate}
                        color="primary"
                        autoFocus
                      >
                        <StyledText>Cancel</StyledText>
                      </Button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Button
                        onClick={this.handleCreateCaseSet}
                        color="primary"
                        autoFocus
                      >
                        <StyledText>+ Create Case Set</StyledText>
                      </Button>
                      <Button
                        onClick={this.handleClose}
                        color="primary"
                        autoFocus
                      >
                        <StyledText>{this.props.dialogButton}</StyledText>
                      </Button>
                    </Fragment>
                  )}
                </DialogActions>
              </DialogContainer>
            </Dialog>
          );
        }}
      </Query>
    );
  }
}
export default withMobileDialog()(CaseSetDialog);

const DialogContainer = styled.div`
  width: 100%;
`;
const CaseSet = styled.div`
  margin-bottom: 15px;
  padding: 10px 20px 25px 5px;
  font-family: futura-pt, sans-serif;
  font-size: 18px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  width: 500px;
  span {
    margin-left: 15px;
  }
  @media (max-width: 959px) {
    width: 95%;
  }
  @media (max-width: 786px) {
    span {
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    width: inherit;
  }
  ${props => props.included && `color:${purple.bright}`}
`;

const StyledButton = styled.div`
  color: white;
  background: ${purple.bright};
  padding: 8px;
  font-family: futura-pt, sans-serif;
  border-radius: 4px;
`;
const StyledText = styled.div`
  font-family: futura-pt, sans-serif;
`;

const ErrorCaseSets = styled.div`
  color: gray;
  text-align: center;
  font-family: futura-pt, sans-serif;
  margin: 15px 0;
  font-size: 18px;
`;

const StyledDialogTitle = styled.div`
  font-family: futura-pt, sans-serif;
  padding: 25px 25px 5px 25px;
  font-size: 24px;
  font-weight: 700;
  @media (max-width: 786px) {
    font-size: 18px;
  }
`;

const InputContainer = styled.div`
  padding: 15px 0;
`;

const TextInput = styled.div`
  padding: 8px;
  border: 1px solid lightgray;
  border-radius: 4px;
  input {
    font-family: adobe-text-pro, serif;
    font-size: 18px;
    line-height: 30px;
    outline: none;
    width: 100%;
    border: none;
    width: 500px;
  }
  @media (max-width: 500px) {
    input {
        width: 100%;
    }
  }
`;
